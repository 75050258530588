import React, { Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import awpServices from "../../services/awpServices";
import Spinner from "react-bootstrap/Spinner";
import UncaughtError from "../../common/Alert/UncaughtError";
import { UploadSuccess, UploadError } from "../../common/Alert/UploadMsg";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Cookies from "js-cookie";
import { _getCaseCommuncationMessageHistory } from "../../redux/actions/AWP/caseCommunicationActions";

const SendResponse = ({ commDetails }) => {
  const location = useLocation();
  const dispatch = useDispatch(); //calling redux actions
  const [textarea, setTextArea] = useState("");
  const [showText, setShowText] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [errTextarea, seterrTextarea] = useState("");
  const [haserror, setHasError] = useState("");
  const [loading, setLoading] = useState(false);
  const useremailid = Cookies.get("useremailid"); 

  const fileInput = useRef(null);
  const [respmsg, setRespMsg] = useState(null);
  const [validationerror, setValidationError] = useState("");
  const [btndisabled, setBtnDisabled] = useState(false);

  const [attachements, setAttachements] = useState([]);

  /* istanbul ignore next */
  const Text = () => (
    <span
      id="confirm-response"
      data-testid="resmsg"
      className="alert alert-success icon btn-responce"
    >
      {" "}
      Response sent
    </span>
  );
  /* istanbul ignore next */
  const handleControl = () => {
    setShowText(false);
    setDisable(false);
    seterrTextarea("");
    setHasError("");
  };
  /* istanbul ignore next */
  const _responce_Send = (e) => {
    e.preventDefault();
    setHasError("");
    if (textarea.trim() === null || textarea.trim() === "") {
      seterrTextarea("Please enter the response message !!");
    } else {
      setLoading(true);
      fnsendreplymail()
      //fnsendresponse();
    }

    function fnsendreplymail() {      
      awpServices
        ._sendCommunicationReply({
          cceId: commDetails.cceId,
          clientId: commDetails.clientId,
          caseCommId: commDetails.communicationId,
          from: commDetails.frommailid,
          to: commDetails.tomailid,
          responseMessage: textarea,
          attachmentList: attachements
        })
        .then((res) => {          
          /* istanbul ignore if */
          if (res.status === 201 || res.status === 200) {
            fnSaveUserActivity();
            setLoading(false);
            setShowText(true);
            setTextArea(" ");
            setDisable(true);  
            setAttachements([]);         
            dispatch(_getCaseCommuncationMessageHistory(commDetails.communicationId,useremailid));
          } /* istanbul ignore else */ else {
            setLoading(false);
            setHasError("error");
          }
        })
        .catch((err) => {
          setLoading(false);
          setHasError(err);
        });
    }
  };


  function fnSaveUserActivity() {
    const filelist =  attachements.map((value)=>{
      return value.Filename
  });
    awpServices
      ._saveCaseCommunicationUserActivity({
        caseCommId: commDetails.communicationId,
        activityType: 'Sent',   
        files: filelist,
        memberDetails: commDetails.memberDetails
      })
      .then((res) => {
        
      })
      .catch((err) => {

      });
  }



const refRecaptcha = useRef(null);
const Upload_Handler = (e) => {
  e.preventDefault();
  const inpt = document.getElementsByTagName("input")[0];

  inpt.onclick = function () {
    this.value = null;
  };
  inpt.onChange = function () { };
  setValidationError("");
  setRespMsg(null);
  if (e.target.files) {
    const typeformat = ["pdf", "gif", "jpg", "png"];
    let { name, size, type } = e.target.files[0];
    //let docext1 = name.split(".").reverse()[0];
    //let docext = name.split(".")[1];
    let docext = type.split("/")[1];
    if (name.split(".").reverse()[0] === "jpg") docext = "jpg";
    if (name.split(".").reverse()[0] === "msg") docext = "msg";
    if (typeformat.includes(docext.toLowerCase())) {
      UploadFile(e.target.files[0], name, docext, size, e.target.value, type);
    } else {
      setValidationError(
        "Selected file type is not allowed. Please select correct file type."
      );
      e.target.value = "";
    }
  } else {
    /* istanbul ignore next */
    setValidationError("Please select  file !!");
  }
};

const UploadFile = (docfile, docname, docext, docsize, docpath, contentype) => {
  if (docsize > 5242880) {
    /* istanbul ignore next */
    setValidationError("File is too large. Max size is 5 mb !!");
  } else {
    const reader = new FileReader();
    reader.readAsDataURL(docfile);
    reader.onload = () => {
      let { result } = reader;
      let index = result.indexOf("base64") + 7;
      let filebyte = result.slice(index);
      submitFile(filebyte, docname, docext, docpath, contentype);
    };
  }
};

const handleRemoveAttachment = (index) => {
  const updatedAttachments = [...attachements];
  updatedAttachments.splice(index, 1);
  setAttachements(updatedAttachments);
  setBtnDisabled(false);
};

const submitFile = async (file, filename, ext, path, contentype) => {  
  const filedetails = {
    filebyte: file,
    Filename: filename,
    fileExtension: `.${ext}`,
    Path: path,
    ContentType: contentype
  };
    //const newAttachments = Array.from(filedetails);
  setAttachements(prevAttachements => [...prevAttachements, filedetails]);
  if (attachements.length >= 4) {
    setBtnDisabled(true);
  }
  setLoading(false);
};

return (
  <div>
    {" "}
    <div id="response-form" className="form-group">
      <Form.Label className="font-weight-bold small" data-testid="SendRes">
        Send a Response
      </Form.Label>
      <p className="small mb-2">
        Missing or incorrect information? Let us know by sending a response.
      </p>
      <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
        <Form.Control
          as="textarea"
          data-testid="SendResTextArea"
          rows={3}
          value={textarea}
          limit={1000}
          maxLength="1000"
          onChange={(e) => setTextArea(e.target.value)}
          onClick={handleControl}
        />
      </Form.Group>
      <span className="errmsg">{errTextarea}</span>
      <p className="small text-right mb-0">
        <span id="count">{1000 - textarea.length}</span> of 1000 characters
        remaining
      </p>

    </div>
    <div className="row">
      <div className="col-lg-12">
        <div style={{ height: '180px' }}>
          {attachements.length > 0 ? (attachements.map((file, index) => (
            <div key={index}>
              <span>{file.Filename}</span>
              <a className="linkstyle" onClick={() => handleRemoveAttachment(index)}>
                <span className="closeIconStyle">&nbsp;&times;</span>
              </a>
            </div>
          ))) : ""}
        </div>
        <React.Fragment>
          <input
            ref={fileInput}
            data-testid="fileupload"
            onChange={Upload_Handler}
            type="file"
            style={{ display: "none" }}
            variant="primary"
            className="btn-sm"
            multiple={true}
          />
          <Button
            className="btn btn-success"
            disabled={btndisabled}
            data-testid="btnfileupload"
            onClick={() => fileInput.current.click()}
          >
            Upload Attachment <i className="fas fa-file-upload ml-2"></i>
          </Button>{" "}
          <p className="small">
            Suggested file types: <b>PDF</b>, <b>PNG</b>, <b>JPG</b>, <b>GIF</b>
            <br />
            Maximum file size: <b>5 MB</b>.
            <br />
            Max No.of Attachements Allowed: <b>5</b>.
          </p>
          <span className="errmsg"> {validationerror}</span>
        </React.Fragment>
        {/* {loading ? (
            <Spinner
              animation="border"
              variant="primary"
              className="btnloading"
            />
          ) : (
            <>
              {" "}
              {respmsg === true ? (
                <UploadSuccess />
              ) : respmsg === false ? (
                <UploadError />
              ) : null}
            </>
          )} */}
      </div>
    </div>
    <br />
    <div style={{ display: "flex" }}>
      <button style={{ marginLeft: "auto" }}
        className="btn  btn-success btn-sm"
        data-testid="sendresbtn"
        onClick={_responce_Send}
        disabled={commDetails.isnonphi === null ? true : disable}
      >
        Send Response
      </button>{" "}
      {showText ? <Text /> : null}
      {loading && (
        <Spinner
          animation="border"
          variant="primary"
          className="btnloading"
        />
      )}
      <br />
      {haserror && <UncaughtError />}
    </div>

  </div>
);
};

export default SendResponse;
