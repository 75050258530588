import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Recaptcha from "react-google-invisible-recaptcha";
import {
  formatPhoneNumber,
  _onlyNumric,
  _patientLastNamespecialchar,
} from "../../common/Validation/validation";

import Loader from "../../common/Loader/Loading";

import awpServices from "../../services/awpServices";
import { LoginAlert } from "../../common/Alert/LoginAlert";
import SingupContent from "../../common/Content/SingupContent";
import Cookies from "js-cookie";
const CreateUser = (props) => {
  //calling redux actions
  const [alertflag, setAlertflag] = useState(false);
  const [loading, setIsloading] = useState(false);
  const [exError, setExError] = useState("");

  const [validatemsg, setValidatemsg] = useState("");
  //recapcha
  const [createuser, setCreateuser] = useState({
    firstName: "",
    lastName: "",
    appusertype: "",

    email: "",
    mobilePhone: "",
  });
  const refRecaptcha = useRef(null);

  useEffect(() => {
    /* istanbul ignore next */
    setExError("");
    /* istanbul ignore next */
    Cookies.remove("statetoken");
  }, []);

  const onsubmitHanlder = (e) => {
    /* istanbul ignore next */
    e.preventDefault();
    /* istanbul ignore next */

    if (
      createuser.appusertype.trim() === "" ||
      createuser.appusertype.trim() === null
    ) {
      /* istanbul ignore next */
      setValidatemsg("Please select party type.");
    } else {
      /* istanbul ignore next */
      setExError("");
      /* istanbul ignore next */
      setIsloading(true);
      refRecaptcha.current
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "signup" })
        .then(async (token) => {
          awpServices
            ._Register_user(createuser)
            .then((res) => {
              /* istanbul ignore next */
              setIsloading(false);
              /* istanbul ignore if */
              if (res.status === 200 || res.status === 201) {
                /* istanbul ignore next */
                setAlertflag(true);
                /* istanbul ignore next */
                setExError("");
                /* istanbul ignore next */
                setValidatemsg("");
                /* istanbul ignore next */
                setCreateuser({
                  firstName: "",
                  lastName: "",
                  appusertype: "",
                  email: "",
                  mobilePhone: "",
                });
                /* istanbul ignore next */
                Cookies.set("statetoken", res.data.stateToken);
              } else {
                /* istanbul ignore next */
                setExError(res.data.message);
              }
            })
            .catch((err) => {
              /* istanbul ignore next */
              setIsloading(false);
              /* istanbul ignore next */
              if (err.code == "ERR_NETWORK") {
                /* istanbul ignore next */
                setExError(
                  `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
                );
              } /* istanbul ignore next */ else if (
                err.response.data.statusCode === 400
              ) {
                /* istanbul ignore next */
                setExError("User already exists.");
              } else {
                /* istanbul ignore next */
                setExError(
                  `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
                );
              }
            });
        })
        .catch((err) => {
          setExError(
            `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
          );
        });
    }
  };

  const updateFields = (e) => {
    setExError("");
    setValidatemsg("");
    setAlertflag(false);
    setCreateuser({
      ...createuser,
      [e.target.name]: e.target.value,
    });
  };
  const list = [
    {
      label: "Attorney",
      value: "attorney",
    },
    {
      label: "Insurance",
      value: "insurance",
    },
  ];

  return (
    <>
      {/* <BrowerNotSupport num={2} /> */}
      <div className="row d-flex justify-content-between mt-2 createuser">
        <div className="col-md-7 col-lg-5 mb-4">
          <div className="bg-light p-4 border mr-0 mr-md-3 mr-lg-4">
            <h1 className="mpi-hdr-3 mb-3">Sign Up</h1>
            {alertflag && (
              /* istanbul ignore next */
              <LoginAlert
                type={"success"}
                errmsg="Request submitted successfully!!"
                formattext={
                  "Please check your email inbox to activate your account."
                }
              />
            )}
            {exError !== "" ? (
              /* istanbul ignore next */ <LoginAlert
                type={"danger"}
                errmsg={exError}
                formattext=""
              />
            ) : null}
            <Form
              className="flow-sm"
              onSubmit={onsubmitHanlder}
              autoComplete="off"
            >
              <Form.Group>
                <Form.Label className="font-weight-bold " id="first-name">
                  First Name{" "}
                </Form.Label>
                <Form.Control
                  id="first-name"
                  data-testid="first-name"
                  type="text"
                  aria-labelledby="first-name"
                  onKeyPress={(e) => _patientLastNamespecialchar(e)}
                  required
                  name="firstName"
                  onChange={updateFields}
                  value={createuser.firstName}
                  className="form-control form-control-sm"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold " id="last-name">
                  {" "}
                  Last Name
                </Form.Label>
                <Form.Control
                  id="last-name"
                  aria-labelledby="last-name"
                  data-testid="last-name"
                  type="text"
                  name="lastName"
                  onKeyPress={(e) => _patientLastNamespecialchar(e)}
                  required
                  className="form-control form-control-sm"
                  value={createuser.lastName}
                  onChange={updateFields}
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
              </Form.Group>

              <Form.Group className="">
                <Form.Label className="font-weight-bold" id="appusertype">
                  Submitting Party
                </Form.Label>
                <Form.Control
                  as="select"
                  role="select"
                  required
                  name={"appusertype"}
                  aria-labelledby="appusertype"
                  data-testid="selectinsurer"
                  className="form-control form-control-sm"
                  onChange={updateFields}
                  value={createuser.appusertype}
                >
                  <option className="d-none" value={"0"}>
                    - Select -
                  </option>
                  {list &&
                    list.map((option, index) => (
                      <option key={index} value={option.value}>
                        {" "}
                        {option.label}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold " id="email">
                  {" "}
                  Email
                </Form.Label>
                <Form.Control
                  id="email"
                  input
                  data-testid="email"
                  type="email"
                  aria-labelledby="email"
                  placeholder="name@example.com"
                  name="email"
                  required
                  //onKeyPress={(e) => _AlphaNumric_Dot_dash(e)}
                  onChange={updateFields}
                  value={createuser.email}
                  className="form-control form-control-sm"
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold " id="mobilePhone">
                  {" "}
                  Phone
                </Form.Label>
                <Form.Control
                  id="mobilePhone"
                  required
                  data-testid="mobilePhone"
                  type="tel"
                  name="mobilePhone"
                  aria-labelledby="mobilePhone"
                  maxLength="10"
                  minLength="10"
                  onKeyPress={(e) => _onlyNumric(e)}
                  onChange={updateFields}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  value={formatPhoneNumber(createuser.mobilePhone)}
                  className="form-control form-control-sm"
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
                <span className="errmsg">{validatemsg}</span>
              </Form.Group>
              {loading ? (
                <Loader />
              ) : (
                <Button
                  variant="primary"
                  className="btn btn-success btn-sm"
                  type="submit"
                  data-testid="btnsingup"
                  //onClick={register_handler}
                >
                  Sign Up
                </Button>
              )}
              <Recaptcha
                ref={refRecaptcha}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                // onResolved={onResolved}
              />
            </Form>
          </div>
        </div>
        <div className="col-md-5 col-lg-6">
          <SingupContent />
        </div>
      </div>
    </>
  );
};

export default CreateUser;
