import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";

function PrivateLogin({ children }) {
  const auth = Cookies.get("statetoken");

  return auth ? children : <Navigate to="/page-unathorized" />;
}

export default PrivateLogin;
