import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import data from "../../VersionDetails.json"


const VersionDetails = ({ show, closeHandler }) => {
    const onHide = () => {
        closeHandler();
    };

    return (
        <Modal
            size="lg"
            show={show}
            aria-labelledby="example-modal-sizes-title-lg"
            data-testid="modal"
        >
            <Modal.Header>
                <Modal.Title
                    id="example-modal-sizes-title-sm"
                    className="h5 modal-title"
                >
                    Version Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "300px" }} className="modal-scrolling">
                {data.map((item) => (
                    <div key={item.version}>
                        <strong>{item.version}</strong>
                        <ul>
                            {item.details.map((details, index) => (
                                <li key={index}>{details.desc}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    data-testid="btntermsok"
                    className="btn btn-success btn-sm"
                    onClick={onHide}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VersionDetails;
