import React from "react";
import Alert from "react-bootstrap/Alert";
const CaseDetailsPHI = ({ PHI }) => {
  let blankarr = [];
  const asArray = Object.entries(PHI).filter(
    ([key, value]) => value?.trim() === ""
  );

  const justStrings = Object.fromEntries(asArray);
  let filterarray = Object.keys(justStrings);
  let cnt = 0;
  let newarr = filterarray.map((val) => {
    if (val === "caseNumber") blankarr.push("Case #");
    else if (val === "memberName") blankarr.push("Member Name");
    else if (val === "coveredIndividualName") blankarr.push("CI Name");
    else if (val === "dateOfLoss") blankarr.push("DOL");
    // else if (val === "injuriesDescription" && val === "incidentDescription")
    //   blankarr.push("Injuries Incident Description");
    else if (val === "injuriesDescription") cnt++;
    // blankarr.push("Injuries  Description");
    else if (val === "incidentDescription") cnt++;
    //blankarr.push("Incident Description");
    else if (val === "lastDateOfTreatment")
      blankarr.push("Last Date of Treatment");
    else if (val === "lienAmount") blankarr.push("Outstanding Lien");
  });
  if (cnt === 2) blankarr.push("Injuries Incident Description");
  //console.log("sss", blankarr, "NEW ARRR", cnt);
  return (
    <>
      {" "}
      {blankarr.length > 0 ? (
        <Alert variant="warning">
          {" "}
          Please use the Send a Response field above to add the information for:
          <ul style={{ fontSize: "12px" }}>
            {blankarr && blankarr.map((item, i) => <li key={i}>{item}</li>)}
          </ul>
        </Alert>
      ) : null}
      <div className="row">
        <div className="col-md-6">
          <dl className="row mt-3 small">
            <dt className="col-md-3">Case #:</dt>
            <dd className="col-md-9">
              {PHI && (PHI.caseNumber?.trim() === "" || PHI.caseNumber === null)
                ? ""
                : PHI.caseNumber}
            </dd>

            <dt className="col-md-3">Member Name:</dt>
            <dd className="col-md-9">
              {PHI && (PHI.memberName?.trim() === "" || PHI.memberName === null)
                ? ""
                : PHI.memberName}
            </dd>

            <dt className="col-md-3">CI Name:</dt>
            <dd className="col-md-9">
              {PHI &&
              (PHI.coveredIndividualName?.trim() === "" ||
                PHI.coveredIndividualName === null)
                ? ""
                : PHI.coveredIndividualName}
            </dd>

            <dt className="col-md-3">DOL:</dt>
            <dd className="col-md-9">
              {PHI && (PHI.dateOfLoss?.trim() === "" || PHI.dateOfLoss === null)
                ? ""
                : PHI.dateOfLoss}
            </dd>
          </dl>
        </div>

        <div className="col-md-6">
          <dl className="row mt-lg-3 small">
            <dt className="col-md-5">Injuries Incident Description:</dt>
            <dd className="col-md-7">
              {PHI &&
              (PHI.injuriesDescription?.trim() === "" ||
                PHI.injuriesDescription === null)
                ? ""
                : PHI.injuriesDescription}
              <br />
              {PHI && PHI.incidentDescription}
            </dd>

            <dt className="col-md-5">Last Date of Treatment:</dt>
            <dd className="col-md-7">
              {PHI &&
              (PHI.lastDateOfTreatment?.trim() === "" ||
                PHI.lastDateOfTreatment === null)
                ? ""
                : PHI.lastDateOfTreatment}
            </dd>

            {/* <dt className="col-md-5">Export Itemization:</dt>
          <dd className="col-md-7">{PHI && PHI.lastDateOfTreatment}</dd> */}

            <dt className="col-md-5">Outstanding Lien:</dt>
            <dd className="col-md-7">
              {PHI && (PHI.lienAmount?.trim() === "" || PHI.lienAmount === null)
                ? ""
                : PHI.lienAmount}
            </dd>
          </dl>
        </div>
      </div>
    </>
  );
};

export default CaseDetailsPHI;
