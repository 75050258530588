import React from "react";

const PatientInfoIcon = (props) => {
  return (
    <p className="mb-0 text-primary">
      <i className="fas fa-info-circle"></i> Provide any{" "}
      <strong>{props.displaytext}</strong> of the following...
    </p>
  );
};

export default PatientInfoIcon;
