import React from "react";
import Alert from "react-bootstrap/Alert";
/* istanbul ignore next */
const CommonError = ({ type, errormsg }) => {
  return (
    <div className="container commmon-error-bg">
      <div className="d-flex justify-content-center">
        <span>
          The application has encountered an unexpected error. Please try again
          after a short time, or you can contact us{" "}
          <a
            href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
          >
            via email
          </a>{" "}
          or call {`${process.env.REACT_APP_SUPPORT_PHONE}`} for assistance.
        </span>
      </div>
    </div>
  );
};

export default CommonError;
