import React, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";

import { Link } from "react-router-dom";
const SingupContent = (props) => {
  return (
    <div>
      {" "}
      <p className="loginbold">
        {" "}
        Welcome to MultiPlan’s Subro Portal account setup!
      </p>
      <p className="mb-1">
        We need a bit of information to create your account.
      </p>{" "}
      <p className="">
        MultiPlan uses Multi-Factor Authentication. You will be provided a
        verification code via email when logging into your account.
      </p>{" "}
      <br />
      <p className="">
        {" "}
        Already have an account? <Link to="/">Sign in</Link>.
      </p>
      <br />
      <br />
      <br />
      <br />
      <p className="loginbold mb-1">Having trouble signing in?</p>
      <p className="">
        Contact us{" "}
        <a
          href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
        >
          via email
        </a>{" "}
        or call {`${process.env.REACT_APP_SUPPORT_PHONE}`} for assistance.
      </p>
    </div>
  );
};

export default SingupContent;
