import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
const PrivacyPolicy = ({ show, closeHandler }) => {
  const onHide = () => {
    /* istanbul ignore next */
    closeHandler();
  };
  return (
    <div data-testid="modalcontainer">
      <Modal
        size="lg"
        show={show}
        className="modal"
        data-testid="modal"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title
            id="example-modal-sizes-title-sm"
            className="h4 modal-title"
          >
            Privacy Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "300px" }} className="modal-scrolling">
          <p>
            MultiPlan is committed to maintaining the highest level of
            confidentiality for all of the information received from our
            customers, including information received from this web site. When
            we receive personal information, we respect the privacy and the
            confidentiality of that information. We collect and maintain
            information to administer our business and to provide products and
            services to our health care customers and their members. We provide
            security safeguards in handling and maintaining personal information
            to protect against risks such as loss, destruction, or misuse. We do
            not engage in the sale or trading of mailing lists or personal
            information. We require our business affiliates to follow the same
            or substantially similar privacy principles.{" "}
          </p>
          <p>
            {" "}
            This privacy policy applies to information we collect from you or
            that you may provide when you visit our website; this policy does
            not apply to information collected by MultiPlan offline or through
            any other means. If you do not agree with our policies and
            practices, your choice is not to use our website. By accessing or
            using this website, you agree to this privacy policy.
          </p>
          <p>
            The MultiPlan Privacy Office is located at 16 Crosby Drive, Bedford,
            MA 01730, and can be contacted by letter there, or by email at{" "}
            <a
              href={`mailto:privacy@multiplan.com`}
              style={{ color: "#03a73d" }}
            >
              {" "}
              privacy@multiplan.com.
            </a>{" "}
          </p>
          <b>A. Definitions</b>
          <br />
          <p>In this Privacy Policy, the following terms will be used:</p>
          <p>
            {" "}
            <u>Personal data:</u> means any data collected during your visit to
            our website that can be used to identify an individual or household,
            directly or indirectly; personal data includes attributes such as a
            name, location data, an online identifier, or other factors specific
            to the physical, cultural, or social identity of the person.
          </p>
          <p>
            {" "}
            <u>Processing:</u> means any operation which is performed on
            personal data, including the collection, recording, organization,
            structuring, storage, adaptation or alteration, retrieval,
            restriction, destruction, or any kind of disclosure of personal
            data.
          </p>
          <b>B. Data Collection and Processing</b>
          <br />
          <p>
            MultiPlan (we, us, and our) will only use the personal data that we
            gather from operating this website as set forth in this policy.
            Please review the way we collect and use personal data; for what
            purposes we collect and use personal data; with whom we may share
            personal data; and what control and information rights you may have
            with respect to your personal data. MultiPlan publishes a searchable
            provider directory and information about our company to the general
            public. We also host several access-restricted portals for specific
            user groups. Visitors to these two types of MultiPlan websites may
            expect different collection, processing, and use of their personal
            data.
          </p>
          <p>
            {" "}
            <u>Public-Facing Websites:</u> When you visit our website for
            informational reasons without setting up an account, only limited
            personal data will be collected and/or processed to provide you with
            access to the website itself. For example:
          </p>
          <ul className="" style={{ marginLeft: "80px" }}>
            <li>the date and time of your visit;</li>
            <li>your device type, screen resolution, and browser version;</li>
            <li>your internet service provider;</li>
            <li>
              information on your operating system, including language settings;
              and
            </li>
            <li>information on how you arrived at our website.</li>
          </ul>
          <p>
            Such information is necessary for us to administer the website, and
            we use this data only to assist us in providing an effective service
            (e.g. to ensure our content is accessible on your device), and to
            collect broad demographic information for anonymized, aggregated
            use.
          </p>
          <p>
            You may also choose to supply your contact information, for example,
            to subscribe to a newsletter, or to request additional information
            via the web form on the Contact Us page. This information will be
            used to communicate with you as needed, and will not be shared with
            any third party.
          </p>
          <p>
            {" "}
            <u>Access-Restricted Portals:</u> : When you interact with one or
            more of our access-restricted portals or subscribe to our
            newsletters or other outreaches, further personal data will be
            collected and/or processed in the scope of such services.
            Interaction with these portals may allow us to collect:
          </p>
          <ul className="" style={{ marginLeft: "80px" }}>
            <li>
              Contact and demographic information about you, for example, your
              name and email address;
            </li>
            <li>
              Account creation and authentication information, for example, your
              chosen username; and
            </li>
            <li>
              Communications sent or received by you, for example, submission of
              updated demographic information or other information or documents
              which may contain sensitive information.
            </li>
          </ul>
          <p>
            This information is necessary for us to provide you secure access to
            our portals, to administer the website, to communicate with you as
            needed, and to fulfill our contractual obligations.
          </p>
          <p>
            Our website is not intended for children under 13 years of age, and
            we do not knowingly collect personal information from children under
            13. If you are under 13, do not use or provide any information on
            this website. If we learn we have collected or received personal
            information from a child under 13 without verification of parental
            consent, we will delete that information. If you believe we might
            have any information from or about a child under 13, please contact
            us at 16 Crosby Drive, Bedford, MA 01730, or by email at
            <a
              href={`mailto:privacy@multiplan.com`}
              style={{ color: "#03a73d" }}
            >
              {" "}
              privacy@multiplan.com.
            </a>{" "}
            For all visitors to our website, we deposit and track cookies.
            Cookies are files with small amount of data, which may include an
            anonymous unique identifier for each visitor. Our website is then
            able to recognize repeat users and track usage patterns to better
            serve visitors when they return to that website. The cookie does not
            extract personal information, and most browsers provide a simple
            procedure that enables users to control whether or not they want to
            receive cookies or notifies them when a website is about to deposit
            a cookie file. You can instruct your browser to refuse all cookies
            or to indicate when a cookie is being sent. However, if you do not
            accept cookies, you may not be able to use some portions of our
            website.
          </p>
          <b>C. Data Security and Retention</b>
          <p>
            We have implemented appropriate safeguards to secure your personal
            data, and only authorized personnel have access to personal data.
            Our HITRUST-certified security program and its related policies are
            regularly reviewed. We cannot guarantee the security of information
            submitted while it is in transit, however, and the submission of
            personal data is at your own risk.
          </p>
          <p>
            The safety and security of your personal information also depends on
            you. Where we have given you (or where you have chosen) a password
            for access to certain parts of our website, you are responsible for
            keeping this password confidential. We ask you not to share your
            password with anyone.
          </p>
          <p>
            We will retain data about you only as long as necessary to fulfill
            the purposes for which we collected it. If applicable regulatory or
            contractual retention timelines apply, we will retain your personal
            data as required.
          </p>
          <b>D. Data Analytics</b>
          <p>
            To improve and optimize our website, we use Google analytics and our
            own proprietary analytics tools to collect information about the use
            of our site. Generally, we collect information such as device and
            browser information, IP addresses, geographic locations, time and
            length of visit, pages accessed, and referring site.
          </p>
          <b>E. Information Sharing</b>
          <p>
            We may disclose anonymized, aggregated data and statistics about our
            website and its users to third parties, including, for example,
            prospective clients or partners. This statistical information will
            not include personal data.
          </p>
          <p>
            Your personal data may be disclosed to a third party only to the
            extent needed to provide services to you, to fulfill our contractual
            relationships, or for participating providers. Any third party
            organizations that are granted access to personal information are
            contractually required to use or process your personal data only in
            compliance with our service provision and all applicable laws.
          </p>
          <p>
            {" "}
            As part of this permitted information sharing, your personal data
            may be transferred across borders to other countries, whose data
            protection requirements may differ from ours. Personal data
            processed in other countries may be subject to the laws of that
            country, including foreign governments, law enforcement, or legal
            systems.
          </p>
          <p>
            Finally, we may disclose personal data in the context of a company
            reorganization or acquisition by a third party, or if we are legally
            entitled or required to do so.
          </p>
          <b>F. Your Rights</b>
          <p>
            Under the laws applicable to you and your data, you may be entitled
            to exercise certain rights with respect to the way organizations
            like MultiPlan collect or process your personal data. Please consult
            your local laws to determine whether you are entitled to exercise
            some or all of the following rights:
          </p>
          <ul className="" style={{ marginLeft: "80px" }}>
            <li>
              Obtain information about whether your personal data is being
              retained, and for what purpose, and obtain access to the personal
              data retained;
            </li>
            <li>
              Request deletion or restriction of use of your personal data;
            </li>
            <li>
              Withdraw your consent to the processing of your personal data, or
              object on particular grounds that your personal data is subject to
              processing;
            </li>
            <li>
              Lodge complaints before the appropriate data protection agency or
              regulator, or take other actions with respect to a potential
              breach of your rights in the processing of your personal data;
            </li>
            <li>
              Require your personal data in a particular machine-readable format
              be transferred to you, or to another personal data controller; or
            </li>
            <li>
              Refuse to be subjected to automated decision making that would
              produce legal or similarly significant effects.
            </li>
          </ul>
          <b>G. Contacting MultiPlan</b>
          <p>
            If you have any questions or concerns about this Privacy Policy, you
            may email us at{" "}
            <a
              href={`mailto:privacy@multiplan.com`}
              style={{ color: "#03a73d" }}
            >
              {" "}
              privacy@multiplan.com.
            </a>{" "}
            Any information you provide when contacting us will be used only to
            handle your request, and will be deleted upon resolution of your
            question or concern, unless a statutory retention period applies. To
            raise a Privacy related inquiry or concern please contact{" "}
            <a
              href={`mailto:privacy@multiplan.com`}
              style={{ color: "#03a73d" }}
            >
              {" "}
              privacy@multiplan.com.
            </a>{" "}
            To raise a Security related inquiry or concern please contact{" "}
            <a
              href={`mailto:Security@multiplan.com`}
              style={{ color: "#03a73d" }}
            >
              Security@multiplan.com.
            </a>{" "}
          </p>
          <b>H. Amendments to This Privacy Policy</b>
          <p>
            We reserve the right to change this policy from time to time by
            publishing the updated policy to our website.
          </p>{" "}
          <p> This policy was last updated on September 1, 2022.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="btn btn-success btn-sm"
            onClick={onHide}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PrivacyPolicy;
