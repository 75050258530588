import React, { useEffect } from "react";
import MultiPalnLogo from "../assets/img/MPI-logo-130.gif";
import Signout from "../assets/img/power-off.png";
import Userprofile from "../assets/img/profile-user.png";
import Home from "../assets/img/homesolid.png";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import awpServices from "../services/awpServices";
import { _getSesion_Logout } from "../redux/actions/AWP/caseInformationActions";
const Header = () => {  
  let navigate = useNavigate();
  let dispatch = useDispatch(); //redirect to next page
  let session;
  let username;
  // console.log("ss", Cookies.get("sessiontoken"));
  /* istanbul ignore if */
  if (
    /* istanbul ignore next */ window.location.pathname === "/" ||
    /* istanbul ignore next */ window.location.pathname === "/login" ||
    window.location.pathname === "/page-unathorized"
  ) {
    Cookies.remove("sessiontoken");
    Cookies.remove("username");
    Cookies.remove("useremailid");    
    Cookies.remove("TokenExpTime");
    Cookies.remove("sessionId");
    Cookies.remove("accessToken");
    Cookies.remove("accessTokenExpiresIn");
    Cookies.remove("appusertype");
  } else {
    /* istanbul ignore next */
    session = Cookies.get("sessiontoken");
    /* istanbul ignore next */
    username = Cookies.get("username");
  }

  //redux selector
  const { info, statuscode } = useSelector((state) => state.caseinfo);
  // useEffect(() => {
  //   //sif (info != null) console.log("sssssss", info.sessionToken);
  //   if (statuscode === 204) {
  //     Cookies.remove("sessionId");
  //     navigate("/");
  //   }
  // }, [statuscode]);

  const signOut = async (e) => {
    /* istanbul ignore next */
    e.preventDefault();
    let sid = Cookies.get("sessionId");
    if (sid !== "null" && sid !== "undefined" && sid !== "") {
      awpServices
        ._signOut(sid)
        .then((res) => {
          /* istanbul ignore next */
          if (res.status === 200 || res.status === 204) {
            /* istanbul ignore next */
            Cookies.remove("sessiontoken");
            /* istanbul ignore next */
            Cookies.remove("username");
            Cookies.remove("TokenExpTime");
            Cookies.remove("sessionId");
            Cookies.remove("accessToken");
            Cookies.remove("accessTokenExpiresIn");
            Cookies.remove("appusertype");
            // navigate("/");
            window.location.assign("/login");
            dispatch(_getSesion_Logout());
          }
        })
        .catch((err) =>
          /* istanbul ignore next */ window.location.assign("/login")
        );
    } else {
      window.location.assign("/login");
    }
  };
  const gotohome = (e) => {
    window.location.assign("/case-information");
  };
  return (
    <>
      <div className="py-3 py-md-4">
        <div className="logo mb-2"></div>

        <div className="mpi-title text-mpi-gray header">
          Subro Portal
          {info != undefined || username != undefined ? (
            <div className="" style={{ float: "right", marginTop: "-35px" }}>
              {/* <a
                className="btn btn  btn-sm"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="Swapnil Kurjekar"
              > */}
              <img
                src={Home}
                width="20"
                height="20"
                alt=""
                title="Click to home"
                className="mt-1"
                style={{ cursor: "pointer" }}
                onClick={gotohome}
              />{" "}
              &nbsp;
              <img
                src={Userprofile}
                width="20"
                height="20"
                alt=""
                className="mt-1 mr-1"
                style={{ cursor: "pointer" }}
                //onClick={signOut}
              />
              {/* <i className="fa fa-user"></i> &nbsp;{" "} */}
              <label style={{ fontSize: "14px" }}>
                {info != undefined && info != null
                  ? info.loginName
                  : Cookies.get("username")}
              </label>
              &nbsp; {/* </a> */}
              {/* <a
                className=""
                href=""
                onClick={signOut}
                style={{ color: "gray" }}
              > */}
              {/* <i
                  class="fa fa-sign-out"
                  aria-hidden="true"
                  style={{ height: "22px" }}
                ></i> */}
              <img
                src={Signout}
                width="20"
                height="20"
                alt="MultiPlan logo"
                className="mt-1"
                title="Click to sign out"
                onClick={signOut}
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : null}
          {/* <ButtonGroup style={{ float: "right" }}>
            <DropdownButton
              as={ButtonGroup}
              title="Dropdown"
              id="bg-vertical-dropdown-3"
            >
              <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
              <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
            </DropdownButton>
            <Button>
              <i className="fa fa-user"></i> ButtonButton
            </Button>
          </ButtonGroup> */}
        </div>
      </div>
    </>
  );
};
export default Header;
