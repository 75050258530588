import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
const Newtab = () => {
  let navigate = useNavigate(); //redirect to next page
  useEffect(() => {
    window.addEventListener("load", registerOpenTab);
  }, []);

  // registerOpenTab FUNCTION

  const registerOpenTab = () => {
    let tabsOpen = 1;

    while (localStorage.getItem("openTab" + tabsOpen) !== null) {
      tabsOpen++;
    }

    localStorage.setItem("openTab" + tabsOpen, "open");

    if (localStorage.getItem("openTab2") !== null) {
      //window.alert('This application is already running in ' + (tabsOpen - 1) + ' other browser tab(s).')
      navigate(`/newtabpage`);
      unregisterOpenTab();
    }
  };

  // unregisterOpenTab FUNCTION

  const unregisterOpenTab = () => {
    let tabsOpen = 1;

    while (localStorage.getItem("openTab" + tabsOpen) !== null) {
      tabsOpen++;
    }

    localStorage.removeItem("openTab" + (tabsOpen - 1));
  };
  window.addEventListener("beforeunload", unregisterOpenTab);
  return null;
};
export default Newtab;
