import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CookiesBanner from "../../common/CookieBanner/CookiesBanner";
import CaseInsurerInformationContent from "../../common/Content/CaseInsurerContent";
import AlertValidation from "../../common/Alert/AlertValidation";
import { _getCaseInfomation } from "../../redux/actions/AWP/caseInformationActions";
import {
  validcaseid,
  _patientLastNamespecialchar,
} from "../../common/Validation/validation";
import TermsofUse from "../../common/Modal/TermsofUse";
import ErrorValidation from "../../common/Alert/ErrorValidation";
import Loader from "../../common/Loader/Loading";
import Cookies from "js-cookie";
import awpServices from "../../services/awpServices";
import CommonError from "../../common/Alert/CommonError";
import { _getCaseCommuncationDetails, _getCaseCommuncationMemberDetails, _getCaseCommuncationMessageHistory } from "../../redux/actions/AWP/caseCommunicationActions";

const CaseInformation = (props) => {
  let navigate = useNavigate(); //redirect to next page
  const dispatch = useDispatch(); //calling redux actions
  const [patientlastname, setPatientlastname] = useState("");
  const [caseid, setCaseid] = useState("");
  const [alertflag, setAlertflag] = useState(false);
  const [show, setShow] = useState(false); // for terms of use link
  const [caseidErr, setCaseidErr] = useState(""); //show error message
  const [showcookiesbanner, setCookiesBanner] = useState(true);
  const [haserror, setHasError] = useState("");
  const [casecommunicationid, setCaseCommunicationId] = useState("");
  const [casecommunicationidErr, setCaseCommunicationIdErr] = useState(""); //show error message
  const [isFieldRequired, setIsFieldRequired] = useState(true); //show error message
  const [isInsurerNameRequired, setIsInsurerNameRequired] = useState(true); //show error message
  const useremailid = Cookies.get("useremailid"); 

    //redux selector
  const { isloading, statuscode, errmsg } = useSelector(
    (state) => state.caseinfo
  );

   const { commstatuscode, commisloading }= useSelector((state) => 
  ( {commstatuscode : state.casecommdetails.statuscode ,
    commisloading : state.casecommdetails.isloading  
 })
); 
 
  useEffect(() => {
    if(process.env.REACT_APP_CLIENT_NAME === "BCBSNE")
    {
      setIsInsurerNameRequired(false);
    }

    setHasError("");

    if (statuscode && statuscode === 200 && caseid !== "") {
      navigate(`/patient-information`, {
        state: { patientlastname, caseid },
      });
    } 
    else if (statuscode && statuscode === 206 && caseid !== "" ){
      /* istanbul ignore next */
      setAlertflag(true);
    }    
    else if (commstatuscode && commstatuscode === 200  && casecommunicationid !== "") {  
      navigate(`/case-communication`, {
        state: { casecommunicationid },
      });
    }
    else if (commstatuscode && (commstatuscode === 206 || commstatuscode === 417 ) && casecommunicationid !== "" ){
      /* istanbul ignore next */
      setAlertflag(true);
    }
  }, [statuscode,commstatuscode]);

  const onsubmitHanlder = (e) => {
    e.preventDefault();
    /* istanbul ignore next */
    setHasError("");
    setAlertflag(false);
    /* istanbul ignore next */
    if (casecommunicationid == null || casecommunicationid == "") {
      if (!validcaseid.test(caseid)) {
        
        //setAlertflag(true);
        setCaseidErr("Please enter valid case number !!");
        //refRecaptcha.current.reset();
      } else {
        dispatch(_getCaseInfomation(patientlastname, caseid));
      }
    }
    else
    {     
      dispatch(_getCaseCommuncationDetails(casecommunicationid, 0, useremailid));
      dispatch(_getCaseCommuncationMessageHistory(casecommunicationid,useremailid));
      dispatch(_getCaseCommuncationMemberDetails(casecommunicationid));
    } 
  };
  const oncaseid_Handler = (e) => {
    /* istanbul ignore next */
    setHasError("");
    /* istanbul ignore next */
    setCaseidErr("");
    /* istanbul ignore next */
    setCaseid(e.target.value);
    setCaseCommunicationId("");
    setAlertflag(false);
  };

  const oncasecommunicationid_Handler = (e) => {
    /* istanbul ignore next */
    setHasError("");
    /* istanbul ignore next */
    setCaseCommunicationIdErr("");
    setIsFieldRequired(false);
    /* istanbul ignore next */
    setCaseCommunicationId(e.target.value);
    //make empty
    setCaseid("");
    setPatientlastname("");
    setAlertflag(false);
  };

  return (
    <>
      {/* <BrowerNotSupport num={2} /> */}
      <div className="row d-flex justify-content-between mt-2">
        {haserror && <CommonError errormsg={haserror.message} />}
        <div className="col-md-7 col-lg-5 mb-4">
          <div className="bg-light p-4 border mr-0 mr-md-3 mr-lg-4">
            <h1 className="mpi-hdr-3 mb-3">Case Information</h1>
            {errmsg && <ErrorValidation errormsg={errmsg} type="danger" />}
            {alertflag && <AlertValidation type="danger" />}

            <Form
              className="flow-sm"
              onSubmit={onsubmitHanlder}
              autoComplete="off"
            >
              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Patient Last Name
                </Form.Label>
                <Form.Control
                  id="patient-name"
                  data-testid="pname"
                  type="text"
                  required = {isFieldRequired}
                  className="form-control form-control-sm"
                  value={patientlastname}
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onKeyPress={(e) => _patientLastNamespecialchar(e)}
                  onChange={(e) => setPatientlastname(e.target.value)}
                />
                <Form.Text className="small mt-1 mb-0">
                  Last name as it appears on letter
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold">Case #</Form.Label>
                <Form.Control
                  id="case-number"
                  type="text"
                  data-testid="caseid"
                  required = {isFieldRequired}
                  className="form-control form-control-sm"
                  value={caseid}
                  onChange={oncaseid_Handler}
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
                <span className="errmsg">{setCaseCommunicationIdErr}</span>
             {isInsurerNameRequired &&
                <Form.Text className="small mt-1 mb-0">
                  Don't have a case #? Use{" "}
                  <Link to="/insurer-name">Insurer name</Link>
                </Form.Text>
              }
              </Form.Group>             
              <p className="font-weight-bold">OR</p>                   
              <Form.Group>
                <Form.Label className="font-weight-bold">
                    Case Communication ID
                </Form.Label>
                <Form.Control
                  id="case-communication-id"
                  data-testid="casecomid"
                  type="text"
                  //required
                  className="form-control form-control-sm"
                  value={casecommunicationid}
                  // onPaste={(e) => {
                  //   /* istanbul ignore next */
                  //   e.preventDefault();
                  //   return false;
                  // }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  //onKeyPress={(e) => _patientLastNamespecialchar(e)}
                  onChange={oncasecommunicationid_Handler}
                  // onChange={(e) => oncasecommunicationid_Handler}
                />
                <Form.Text className="small mt-1 mb-0">
                  Enter Case Communication ID Received 
                </Form.Text>
              </Form.Group>

              {isloading || commisloading ? (
                <Loader />
              ) : (
                <Button
                  variant="primary"
                  className="btn btn-success btn-sm g-recaptcha"
                  type="submit"
                  data-testid="btnnext"
                >
                  Next
                </Button>
              )}
              {/* <Form.Text className="small mt-4 mb-0 text-center">
                By using this portal you agree to our{" "}
                <a
                  href=""
                  data-toggle="modal"
                  data-target="#terms"
                  onClick={() => setShow(true)}
                >
                  terms of use
                </a>
              </Form.Text> */}
            </Form>
          </div>
        </div>
        <div className="col-md-5 col-lg-6">
          <CaseInsurerInformationContent content="caseinfo" />
        </div>
        {/* {showcookiesbanner && <CookiesBanner />} */}
        {/* {show && <TermsofUse show={show} closeHandler={() => setShow(false)} />} */}
      </div>
    </>
  );
};

export default CaseInformation;
