let actiontypes = {
  CASEINFO_LOAD_START: "CASEINFO_LOAD_START",
  CASEINFO_LOAD_SUCCESS: "CASEINFO_LOAD_SUCCESS",
  CASEINFO_LOAD_ERRORS: "CASEINFO_LOAD_ERRORS",
  INSURER_LOAD_START: "INSURER_LOAD_START",
  INSURER_LOAD_SUCCESS: "INSURER_LOAD_SUCCESS",
  INSURER_LOAD_ERRORS: "INSURER_LOAD_ERRORS",
  INSURER_LOAD_LIST: "INSURER_LOAD_LIST",
  PATIENTINFO_LOAD_START: "PATIENTINFO_LOAD_START",
  PATIENTINFO_LOAD_SUCCESS: "PATIENTINFO_LOAD_SUCCESS",
  PATIENTINFO_LOAD_ERRORS: "PATIENTINFO_LOAD_ERRORS",
  PARTYINFO_LOAD_START: "PARTYINFO_LOAD_START",
  PARTYINFO_LOAD_SUCCESS: "PARTYINFO_LOAD_SUCCESS",
  PARTYINFO_LOAD_ERROR: "PARTYINFO_LOAD_ERROR",
  FIRMNAME_LOAD_LIST: "FIRMNAME_LOAD_LIST",
  FIRMNAMEADD_LOAD_START: "FIRMNAMEADD_LOAD_START",
  FIRMNAMEADD_LOAD_SUCCESS: "FIRMNAMEADD_LOAD_SUCCESS",
  FIRMNAMEADD_LOAD_ERROR: "FIRMNAMEADD_LOAD_ERROR",
  PATIENTINFO_CLEAR: "PATIENTINFO_CLEAR",
  GET_SESSION_MFA: "GET_SESSION_MFA",
  SESSION_CLEAR: "SESSION_CLEAR",
  INSURER_LIST_LOAD_START: "INSURER_LIST_LOAD_START",
  CASECOMM_MEM_LOAD_START: "CASECOMM_LOAD_START",
  CASECOMM_MEM_LOAD_SUCCESS: "CASECOMM_LOAD_SUCCESS",
  CASECOMM_MEM_LOAD_ERRORS: "CASECOMM_LOAD_ERRORS",
  CASECOMM_DETAILS_LOAD_START: "CASECOMM_DETAILS_LOAD_START",
  CASECOMM_DETAILS_LOAD_SUCCESS: "CASECOMM_DETAILS_LOAD_SUCCESS",
  CASECOMM_DETAILS_LOAD_ERRORS: "CASECOMM_DETAILS_LOAD_ERRORS",
  CASECOMM_MES_HIST_LOAD_START: "CASECOMM_MES_HIST_LOAD_START",
  CASECOMM_MES_HIST_LOAD_SUCCESS: "CASECOMM_MES_HIST_LOAD_SUCCESS",
  CASECOMM_MES_HIST_LOAD_ERRORS: "CASECOMM_MES_HIST_LOAD_ERRORS"  
};

export default actiontypes;
