import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const auth = Cookies.get("sessiontoken");

  return auth ? children : <Navigate to="/page-unathorized" />;
}

export default PrivateRoute;
