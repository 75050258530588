import React, { useState } from "react";
import PrivacyPolicy from "../common/Modal/PrivacyPolicy";
import TermsofUse from "../common/Modal/TermsofUse";
import VersionDetails from "../common/Modal/VersionDetails";

const Footer = () => {
  const [show, setShow] = useState(false);
  const [showterms, setShowTerms] = useState(false);
  const [showVersionDetails, setVersionDetails] = useState(false);

  return (
    <>
      <div className="mt-auto py-4 bg-light">
        <div className="container d-flex flex-wrap justify-content-between small">
          <div>
            &copy;

            {`${new Date().getFullYear()} MultiPlan. All rights reserved. `}
            <a
              href=""
              data-toggle="modal"
              data-target="#version"
              className="mr-2"
              onClick={() => setVersionDetails(true)}
            >
              {process.env.REACT_APP_VERSION}
            </a>
            {" "}
          </div>
          <div>
            <a
              href=""
              data-toggle="modal"
              data-target="#terms"
              className="mr-2"
              onClick={() => setShowTerms(true)}
            >
              Terms of Use
            </a>
            <a
              data-target="#privacy"
              className="mr-2"
              href={`${process.env.REACT_APP_PRIVACY_POLICY_URL}`}

              target="_blank"
              rel="noreferrer"
            //onClick={() => setShow(true)}
            >
              Privacy Policy
            </a>
          </div>
          {showterms && (
            <TermsofUse
              show={showterms}
              closeHandler={() => setShowTerms(false)}
            />
          )}
          {showVersionDetails && (
            <VersionDetails
              show={showVersionDetails}
              closeHandler={() => setVersionDetails(false)}
            />
          )}
          {show && (
            <PrivacyPolicy show={show} closeHandler={() => setShow(false)} />
          )}
        </div>
      </div>
    </>
  );
};
export default Footer;
