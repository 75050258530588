import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CookiesBanner from "../../common/CookieBanner/CookiesBanner";

import {
  validcaseid,
  _patientLastNamespecialchar,
} from "../../common/Validation/validation";
import LoginContent from "../../common/Content/LoginContent";
import awpServices from "../../services/awpServices";
import { LoginAlert } from "../../common/Alert/LoginAlert";
import Loader from "../../common/Loader/Loading";
import Cookie from "js-cookie";

import { _getSesion_Logout } from "../../redux/actions/AWP/caseInformationActions";
import CryptoJS from "crypto-js";
import TermsofUse from "../../common/Modal/TermsofUse";
import ToastMsg from "../../common/Alert/ResetPasswordAlert";
import { Alert } from "react-bootstrap";
const Login = () => {
  let navigate = useNavigate(); //redirect to next page
  const dispatch = useDispatch(); //calling redux actions
  const location = useLocation();
  const [showcookiesbanner, setCookiesBanner] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setIsloading] = useState(false);
  const [isshown, setIsshown] = useState(false);
  const [exError, setExError] = useState("");
  const [extext, setExtext] = useState("");
  const [show, setShow] = useState(false); // for terms of use link
  const [authfailed, setAuthfailed] = useState(false); // for terms of use link
  useEffect(() => {
    //setPassword(" ");
    //debugger;
    Cookie.remove("sessiontoken");
    Cookie.remove("username");
    Cookie.remove("statetoken");
    Cookie.remove("TokenExpTime");
    Cookie.remove("sessionId");
    Cookie.remove("accessToken");
    Cookie.remove("accessTokenExpiresIn");
    Cookie.remove("appusertype");
    //console.log("swapnil ");
  }, []);

  const Login_Handler = (e) => {
    e.preventDefault();
    var data = password;
    var keyVal = CryptoJS.enc.Utf8.parse("32sFd3S6l3gm6IUn");
    var iv = CryptoJS.enc.Utf8.parse("5wajvdyv5Xv1hq8J");
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(data),
      keyVal,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();

    //var passwordval = "" + encrypted;
    /* istanbul ignore next */
    setIsloading(true);
    /* istanbul ignore next */
    setExError("");
    /* istanbul ignore next */
    setExtext("");
    setAuthfailed(false);
    awpServices
      ._login(email, encrypted)
      .then((res) => {
        /* istanbul ignore next */
        setIsloading(false);
        /* istanbul ignore next */
        if (res.status === 200 || res.status === 201) {
          //  Cookie.set("username", "Swapnil Kurjekar");
          /* istanbul ignore next */
          Cookie.set("statetoken", res.data.stateToken);
          /* istanbul ignore next */
          navigate("/verificationpage", {
            state: {
              token: res.data.stateToken,
              factorId: res.data.factorId,
              userEmailId:email
            },
          });
          /* istanbul ignore next */
          dispatch(_getSesion_Logout());
          /* istanbul ignore next */
          setExError("");
          /* istanbul ignore next */
          setEmail("");
          /* istanbul ignore next */
          setPassword("");
          //setAuthfailed(false)
        } else if (res.status === 204) {
          /* istanbul ignore next */
          setExError("Locked Out!!");
          /* istanbul ignore next */
          setExtext("Please contact your administrator!");
        } else {
          /* istanbul ignore next */
          setExError(res.data.message);
        }
      })
      .catch((err) => {
        /* istanbul ignore next */
        setIsloading(false);
        /* istanbul ignore next */
        if (err.code == "ERR_NETWORK") {
          /* istanbul ignore next */
          setExError(
            `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
          );
        } /* istanbul ignore next */ else if (
          err.response.data.statusCode === 401
        ) {
          /* istanbul ignore next */
          setExError("");
          setAuthfailed(true);
        } else {
          /* istanbul ignore next */
          setExError(
            `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
          );
        }
      });
  };

  window.onbeforeunload = function () {
    // console.log("ppp");
    /* istanbul ignore next */ sessionStorage.removeItem("passwordsuccessset");
    //window.history.replaceState(null, "");
    // return "Are you sure you want to leave?";
  };

  const PasswordSet = (e) => {
    setIsshown(true);
    setPassword(e.target.value);
  };

  let authenticationfaied = (
    <Alert variant="danger">
      {" "}
      <p
        className="pt-1 small mb-0"
        data-testid="alertmsgcontainer"
        style={{ display: "contents" }}
      >
        We don’t recognize your credentials. Please try again or
        <strong>
          <Link to="/signup"> submit a request </Link>
        </strong>
        and we’ll help you create an account.
        {/* Please check your email inbox to activate your account. */}
      </p>
    </Alert>
  );
  //console.log("ssssssssssssssss", sessionStorage.getItem("passwordsuccessset"));
  //debugger;
  return (
    <>
      {/* <BrowerNotSupport num={2} /> */}
      <div className="row d-flex justify-content-between mt-2">
        <div className="col-md-7 col-lg-5 mb-4">
          <div className="bg-light p-4 border mr-0 mr-md-3 mr-lg-4">
            <h1 className="mpi-hdr-3">Sign In </h1>
            {authfailed ? authenticationfaied : null}
            {sessionStorage.getItem("passwordsuccessset") === "true" ? (
              <ToastMsg
                isshow={true}
                msgtext={
                  "Password successfully changed. Please Sign in with the new password."
                }
              />
            ) : null}
            {exError !== "" ? (
              <LoginAlert
                type={"danger"}
                errmsg={exError}
                formattext={extext}
              />
            ) : null}
            <Form
              className="flow-sm"
              data-testid="form"
              onSubmit={Login_Handler}
              autoComplete="off"
            >
              <Form.Group>
                <Form.Label className="font-weight-bold" id="email">
                  Email Address
                </Form.Label>

                <Form.Control
                  id="email"
                  data-testid="email"
                  type="email"
                  required
                  className="form-control form-control-sm"
                  value={email}
                  aria-labelledby="email"
                  placeholder="Email"
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  //onKeyPress={(e) => _patientLastNamespecialchar(e)}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold" id="password">
                  Password
                </Form.Label>
                <Form.Control
                  id="password"
                  type={`${isshown ? "password" : "text"}`}
                  data-testid="password"
                  placeholder="Password"
                  required
                  className="form-control form-control-sm"
                  value={password}
                  aria-labelledby="password"
                  autoComplete="off"
                  onChange={PasswordSet}
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
                {/* <span className="errmsg">{caseidErr}</span> */}
              </Form.Group>
              {loading ? (
                <Loader />
              ) : (
                <Button
                  variant="primary"
                  className="btn btn-success btn-sm g-recaptcha"
                  type="submit"
                  data-testid="btnsignin"
                >
                  Sign in
                </Button>
              )}
              <Form.Text className="small mt-4 mb-0 text-center">
                By using this portal you agree to our{" "}
                <a
                  href=""
                  data-toggle="modal"
                  data-target="#terms"
                  onClick={() => setShow(true)}
                >
                  terms of use
                </a>
              </Form.Text>
            </Form>
          </div>
        </div>
        <div className="col-md-5 col-lg-6">
          <LoginContent link={true} />
        </div>
        {showcookiesbanner && <CookiesBanner />}
        {show && <TermsofUse show={show} closeHandler={() => setShow(false)} />}
      </div>
    </>
  );
};

export default Login;
