// export default ResetAlert;
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";

function ToastMsg({ isshow, msgtext }) {
  const [show, setShow] = useState(isshow);

  const closetoast = () => {
    /* istanbul ignore next */ sessionStorage.removeItem("passwordsuccessset");
    setShow(false);
  };
  return (
    <Row>
      <Col xs={12}>
        <Toast onClose={closetoast} show={show} delay={10000} autohide>
          <a onClick={closetoast}>
            <i
              className="fa fa-window-close"
              aria-hidden="true"
              style={{
                marginInlineStart: "auto",
                cursor: "pointer",
                float: "right",
              }}
            ></i>
          </a>
          <Toast.Body>{msgtext}</Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
}

export default ToastMsg;
