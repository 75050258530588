import awpServices from "../../../services/awpServices";
import insurercreater from "./insurerActionCreaters";
import actioncreator from "./caseInformationActionCreater";
export const _getInsurerName =
  (_patientlastname, insurer) => async (dispatch) => {
    try {
      /* istanbul ignore next */
      dispatch(insurercreater.insurerLoadStart());
      const res = await awpServices
        ._getinsurerInfo(_patientlastname, insurer)
        .then((result) => {
          return result;
        });
      /* istanbul ignore next */
      if (res.status === 200) {
        /* istanbul ignore next */
        dispatch(actioncreator.PatientInfoLoadClear());
        dispatch(insurercreater.insurerLoadSuccess(res.data));
      }
    } catch (err) {
      /* istanbul ignore next */
      dispatch(insurercreater.insurerLoadErrors(err.message));
    }
  };

export const _getInsurerList = () => async (dispatch) => {
  try {
    /* istanbul ignore next */
    dispatch(insurercreater.insurerListLoadStart());
    const res = await awpServices._getinsurerList().then((result) => {
      return result;
    });
    /* istanbul ignore next */
    if (res.status === 200) {
      dispatch(insurercreater.insurerLoadData(res.data));
    }
  } catch (err) {
    /* istanbul ignore next */
    dispatch(insurercreater.insurerLoadErrors(err.message));
  }
};
