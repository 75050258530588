import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
const Timeoutmodal = ({ showModal, countdown, onContinue, minuts }) => {
  return (
    <Modal
      size="md"
      show={showModal}
      aria-labelledby="example-modal-sizes-title-lg"
      data-testid="modalautologout"
    >
      <Modal.Header className="p-0">
        <Alert
          // className="alert alert-warning icon flex-grow-1"
          style={{ width: "500px" }}
          variant="warning"
        >
          {" "}
          You are about to be timed out.
        </Alert>
        <Modal.Title
          id="example-modal-sizes-title-sm"
          className="h5 modal-title"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-scrolling">
        <p>
          This session will time out in{" "}
          <span id="countdown">
            {minuts}:{countdown}
          </span>{" "}
          seconds
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="btn btn-success btn-sm"
          onClick={onContinue}
        >
          Remain on this page
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Timeoutmodal;
