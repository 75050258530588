export const _onlyAlphaChar = (e) => {
  const reg = /[A-Za-z]+/g;
  if (!reg.test(e.key)) {
    e.preventDefault();
  }
};
export const _onlyAlphaNumric = (e) => {
  const reg = /[A-Za-z 0-9]+/g;
  if (!reg.test(e.key)) {
    e.preventDefault();
  }
};
export const _onlyNumric = (e) => {
  const reg = /[0-9]+/g;
  if (!reg.test(e.key)) {
    e.preventDefault();
  }
};

export const _alphabNumaricspecialchar = (e) => {
  const reg = /^(?=.*[a-zA-Z\d].*)[a-zA-Z\d!@#$%&*]{7,}$/g;
  if (!reg.test(e.key)) {
    e.preventDefault();
  }
};

export const validcaseid = /^[0-9]{1,3}[a-zA-Z]{1,2}[0-9]{4,10}$/;
export const phone =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return cleaned;
}
export const _patientLastNamespecialchar = (e) => {
  const reg = /[A-Za-z 0-9 ,.'-]+/g;
  if (!reg.test(e.key)) {
    e.preventDefault();
  }
};

export const _AlphaNumric_Dot_dash = (e) => {
  const reg = /[A-Za-z 0-9 .-]+/g;
  if (!reg.test(e.key)) {
    e.preventDefault();
  }
};
