import actiontypes from "../../actiontypes/awpActiontypes";

const initialState = {
  isloading: false,
  statuscode: null,
  errmsg: null,
  caseId: null,
  patientlastname: null,
  info: null,
};
const caseinfoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actiontypes.CASEINFO_LOAD_START:
      return {
        ...state,
        isloading: true,
        statuscode: null,
        errmsg: null,
      };
    case actiontypes.CASEINFO_LOAD_SUCCESS:
      return {
        ...state,
        isloading: false,
        statuscode: payload.statusCode, //200
        //caseinfodata: payload.data.query,
        patientlastname: payload.data.query.patientLastName,
        caseId: payload.data.query.caseId,
      };
    case actiontypes.GET_SESSION_MFA:
      /* istanbul ignore next */
      return {
        ...state,
        isloading: false,
        statuscode: payload.statusCode, //200
        info: payload,
        //caseinfodata: payload.data.query,
      };
    case actiontypes.CASEINFO_LOAD_ERRORS:
      return {
        ...state,
        isloading: false,
        errmsg: payload,
        statuscode: payload.statusCode, //404,500
      };
    /* istanbul ignore next */
    case actiontypes.SESSION_CLEAR: //login
      return {
        ...state,
        isloading: false,
        statuscode: null,
        info: null,
        errmsg: null,
        //caseinfodata: payload.data.query,
      };
    default:
      return state;
  }
};

export default caseinfoReducer;
