import React, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";

import { Link } from "react-router-dom";
const ResetPasswordContent = (props) => {
  return (
    <div>
      {" "}
      <p className="loginbold"> Set your new password</p>A few rules to help
      make your password secure :
      <ul>
        <li>Password length must be minimum 12 characters</li>
        <li>
          It must contain at least one capital letter, one number and one
          special character
        </li>
        <li>New password should not be same as previous password.</li>
      </ul>
      <br />
      <br />
      <br />
      <p className="loginbold mb-1">Having trouble signing in?</p>
      <p className="">
        Contact us{" "}
        <a
          href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
        >
          via email
        </a>{" "}
        or call {`${process.env.REACT_APP_SUPPORT_PHONE}`} for assistance.
      </p>
    </div>
  );
};

export default ResetPasswordContent;
