import React, { Fragment, useEffect, useRef, useState } from "react";
import parse from 'html-react-parser';
import Loader from "../../common/Loader/Loading";
import { Spinner } from "react-bootstrap";
import awpServices from "../../services/awpServices";

const CommunicationDetails = ({ messageDetails }) => {

  const [attachementList, setAttachementList] = useState([]);
    /* istanbul ignore next */
    function base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    }
    /* istanbul ignore next */
    function saveByteArray(reportName, byte, contentType) {
      var blob = /* istanbul ignore next */ new Blob([byte], {
        type: contentType,
      });
      var link = /* istanbul ignore next */ document.createElement("a");
      link.href = /* istanbul ignore next */ window.URL.createObjectURL(blob);
      var fileName = reportName;
      link.download = fileName;
      link.click();
    }

  const handleattachementclick = (item) => {
       awpServices
      ._DownloadCommunicationAttachment({
        imageId: item.fileImageId,
        filename : item.fileName,
        fileext : encodeURIComponent(item.contentType)
      })
      .then((response) => {
       // setLoading(false);
        if (response.status === 200 || response.status === 201) {
          var sampleArr = base64ToArrayBuffer(response.data.data.fileByte);
          /* istanbul ignore next */ saveByteArray(
            response.data.data.fileName,
            sampleArr,
            response.data.data.contentType
          );
          // /* istanbul ignore next */ setHasError("");
        } else {
          // //setLoading(false);
          // setHasError(
          //   `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
          // );
        }
      })
      // .catch((err) => /* istanbul ignore next */ setHasError(err));
  };   
  
  function fnSaveUserActivity() {    

    const filelist =  messageDetails.attachementlist.map((value)=>{
        return value.fileName
    });

    awpServices
      ._saveCaseCommunicationUserActivity({
        caseCommId: messageDetails.communicationId,
        activityType: 'Read',   
        files: filelist,
        memberDetails: messageDetails.memberDetails
      })
      .then((res) => {

      })
      .catch((err) => {

      });
  }
  
  useEffect(() => {
    if (messageDetails.statuscode && messageDetails.statuscode === 200) {
      if(messageDetails.attachementlist !== null && messageDetails.attachementlist!== undefined)
      {
        console.log("attachementlist",messageDetails.attachementlist);
        setAttachementList(messageDetails.attachementlist);
      }
      if(messageDetails.memberDetails.statuscode && messageDetails.memberDetails.statuscode === 200)
      {
        fnSaveUserActivity();
      }
    }
   
  }, [messageDetails.statuscode,messageDetails.memberDetails.statuscode]);

  return (
    <Fragment>
      <dl className="row mt-3 small">
        <dt className="col-md-3" data-testid="CaseManager">
          From:
        </dt>
        <label className="col-md-9" data-testid="casemangerinfo">
          {messageDetails.from}
        </label>
        <dt className="col-md-3" data-testid="CaseManager">
          To:
        </dt>
        <label className="col-md-9" data-testid="casemangerinfo">
          {messageDetails.to}
        </label>
        <dt className="col-md-3" data-testid="CaseManager">
          CC:
        </dt>
        <label className="col-md-9" data-testid="casemangerinfo">
          {messageDetails.cc}
        </label>
        <dt className="col-md-3" data-testid="CaseManager">
          Subject:
        </dt>
        <label className="col-md-9" data-testid="casemangerinfo">
          {messageDetails.subject}
        </label>
        <br />
        <br />
        <br />
        <dt className="col-md-3" data-testid="CaseManager">
          Message:
        </dt>
        <br />
        <dd className="col-md-9">
          <br />
          <label  data-testid="casemangerinfo">
            <p>{messageDetails.message}</p>
            <br />
            <p>{messageDetails.signature}</p>
          </label>
        </dd>
        <dt className="col-md-3" data-testid="CaseManager">
          Attachments:
        </dt>
        <dd className="col-md-9">
          <br />
        <label>          
           <ul>
            { attachementList.length > 0 ?(messageDetails.attachementlist.map((item, index) => (              
                <li style={{ width: "320px" }} key={item.imgSk} onClick={() => handleattachementclick(item)}>
                  <dt>
                    <p style={{ wordWrap: "break-word" }}> <strong>{item.fileName}</strong> <i className="fas fa-download ml-2"></i> </p> 
                  </dt>                
                </li>               
              ))):"No Attachments Found"
            }
            </ul>
        </label>
        </dd>

      </dl>
    </Fragment>
  );
};

export default CommunicationDetails;
