import React from "react";
import { Link } from "react-router-dom";
const CaseInsurerInformationContent = (props) => {
  return (
    <div>
      <h2 className="mpi-hdr-3">Get Started</h2>
      <p className="mb-2">
        In order to access case information you'll need to provide the
        following...
      </p>
      <ul>
        <li>Patient last name</li>
        {props.content === "caseinfo" ? (
          <li>Case number</li>
        ) : (
          <li>Insurer name</li>
        )}
      </ul>
      {props.content === "caseinfo" && process.env.REACT_APP_CLIENT_NAME !== "BCBSNE"&& (
        <p>
          You can use
          <strong>
            {" "}
            <Link to="/insurer-name">Insurer name</Link>
          </strong>{" "}
          if you don't have the case number.
        </p>
      )}
      <h3 className="mpi-hdr-5 mt-4">On the next screen</h3>
      <p className="mb-2">
        You'll need to provide at least{" "}
        {props.content === "caseinfo" ? (
          <strong>two</strong>
        ) : (
          <strong>three</strong>
        )}{" "}
        of the following...
      </p>
      <ul>
        <li>Patient DOB</li>
        <li>Zip code</li>
        <li>Member ID</li>
        <li>Group number</li>
      </ul>
      <h3 className="mpi-hdr-5 mt-4">Or</h3>
      <p className="mb-2">Enter the Case Communication ID Received.</p>
      If you don't have all of this information you can contact us{" "}
      <a
        href={`mailto:${process.env.REACT_APP_SUBRO_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
      >
        {" "}
        via email
      </a>{" "}
      or call {`${process.env.REACT_APP_SUBRO_FAX}`} for assistance.
    </div>
  );
};

export default CaseInsurerInformationContent;
