import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CaseDetails from "../components/case-details";
import CaseInformation from "../components/case-information";
import CaseCommunication from "../components/case-communication";
import PartyVerifier from "../components/party-verifier";
import PatientInformation from "../components/patient-information";
import PatientInformationalt from "../components/patient-information-alt";
import InsurerName from "../components/insurer";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import PageNotFound from "../common/Error/404";
import AutoLagoutTimer from "./AutoLogout";
import NewTabPage from "../common/Error/NewTabPage";

import PrivateRoute from "./PrivateRoute";
import Unauthorized from "../common/Error/Unauthorized";
import Login from "../components/login-okta";
import CreateUser from "../components/login-okta/CreateUser";
import Accountverify from "../components/login-okta/Accountverify";
import ResetPassword from "../components/login-okta/ResetPassword";
import LoginverifyMFA from "../components/login-okta/LoginverifyMFA";
import ForgetPasswordEmail from "../components/login-okta/ForgetPasswordEmail";
import PrivateLogin from "./PrivateLogin";
import ReactivationLinkEmail from "../components/login-okta/ReactivationLinkEmail";
const RouteList = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<CreateUser />} />

        <Route
          path="case-information"
          element={
            <PrivateRoute>
              <AutoLagoutTimer ComposedClass={CaseInformation} />
            </PrivateRoute>
          }
        />

        <Route
          path="insurer-name"
          element={
            <PrivateRoute>
              <AutoLagoutTimer ComposedClass={InsurerName} />
            </PrivateRoute>
          }
        />
        <Route
          path="patient-information"
          element={
            <PrivateRoute>
              <AutoLagoutTimer ComposedClass={PatientInformation} />
            </PrivateRoute>
          }
        />
        <Route
          path="patient-information-alt"
          element={
            <PrivateRoute>
              <AutoLagoutTimer ComposedClass={PatientInformationalt} />
            </PrivateRoute>
          }
        />
        <Route
          path="party-verifier"
          element={
            <PrivateRoute>
              <AutoLagoutTimer ComposedClass={PartyVerifier} />
            </PrivateRoute>
          }
        />
        <Route
          path="case-details"
          element={
            <PrivateRoute>
              <AutoLagoutTimer ComposedClass={CaseDetails} />
            </PrivateRoute>
          }
        />
        <Route
          path="case-communication"
          element={
            <PrivateRoute>
              <AutoLagoutTimer ComposedClass={CaseCommunication} />
            </PrivateRoute>
          }
        />
        <Route
          path="verificationpage"
          element={
            <PrivateLogin>
              <LoginverifyMFA />
            </PrivateLogin>
          }
        />
        <Route
          path="setpassword"
          element={
            <PrivateLogin>
              <ResetPassword />
            </PrivateLogin>
          }
        />

        <Route path="verification" element={<Accountverify />} />
        <Route path="forgetpasswordemail" element={<ForgetPasswordEmail />} />
        <Route path="reactivationlinkemail" element={<ReactivationLinkEmail />} />
        <Route path="" element={<Navigate to="/login" />} />
        <Route path="page-not-found" element={<PageNotFound />} />
        <Route path="page-unathorized" element={<Unauthorized />} />

        <Route path="*" element={<Navigate to="/page-not-found" replace />} />
        <Route path="newtabpage" element={<NewTabPage to="/newTabPage" />} />
      </Routes>

      <Footer />
    </>
  );
};

export default RouteList;
