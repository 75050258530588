import React from "react";
import Alert from "react-bootstrap/Alert";
const ErrorValidation = ({ type, errormsg }) => {
  return (
    <Alert variant={type}>
      {" "}
      <span className="" data-testid="errmsgcontainer">
        The application has encountered an unexpected error. Please try again
        after a short time, or You can contact us{" "}
        <Alert.Link
          style={{ color: "#004589", fontWeight: "600" }}
          href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
        >
          via email
        </Alert.Link>{" "}
        or call {`${process.env.REACT_APP_SUPPORT_PHONE}`} for assistance.
      </span>
    </Alert>
  );
};

export default ErrorValidation;
