import React, { Fragment } from "react";
import Alert from "react-bootstrap/Alert";
const AlertValidation = ({ type }) => {
  return (
    <Fragment>
      {" "}
      <Alert variant={type}>
        {" "}
        We cannot find that case
        <p className="pt-1 small mb-0" data-testid="alertmsgcontainer">
          You can contact us{" "}
          <Alert.Link
            style={{ color: "#004589", fontWeight: "600" }}
            href={`mailto:${process.env.REACT_APP_SUBRO_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
          >
            via email
          </Alert.Link>{" "}
          or call {`${process.env.REACT_APP_SUBRO_FAX}`} for assistance.
        </p>
      </Alert>
    </Fragment>
  );
};

export default AlertValidation;
