import actiontypes from "../../actiontypes/awpActiontypes";

const caseInfoLoadStart = () => ({
  type: actiontypes.CASEINFO_LOAD_START,
});
/* istanbul ignore next  */
const caseInfoLoadSuccess = (data) => ({
  type: actiontypes.CASEINFO_LOAD_SUCCESS,
  payload: data,
});

const caseInfoLoadError = (errmsg) => ({
  type: actiontypes.CASEINFO_LOAD_ERRORS,
  payload: errmsg,
});
/* istanbul ignore next  */
const PatientInfoLoadClear = () => ({
  type: actiontypes.PATIENTINFO_CLEAR,
});
const _getsession_mfa = (data) => ({
  type: actiontypes.GET_SESSION_MFA,
  payload: data,
});

const SessionOutClear = (data) => ({
  type: actiontypes.SESSION_CLEAR,
  payload: data,
});
export default {
  caseInfoLoadStart,
  caseInfoLoadSuccess,
  caseInfoLoadError,
  PatientInfoLoadClear,
  _getsession_mfa,
  SessionOutClear,
};
