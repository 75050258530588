import { combineReducers } from "redux";
import caseinfoReducer from "./AWP/caseinformationReducer";
import insurerReducer from "./AWP/InsurerReducer";
import partyInfoReducer from "./AWP/partyInfoReducer";
import patientInfoReducer from "./AWP/patientInfoReducer";
import caseCommMesHistReducer from "./AWP/caseCommunicationMesHistReducer";
import caseCommMemberReducer from "./AWP/caseCommunicationMemberReducer";
import caseCommDetailsReducer from "./AWP/caseCommunicationDetailsReducer";

const rootReducers = combineReducers({
  caseinfo: caseinfoReducer,
  insurerinfo: insurerReducer,
  patientinfo: patientInfoReducer,
  partyinfo: partyInfoReducer,
  casecommdetails: caseCommDetailsReducer,
  casecommhist: caseCommMesHistReducer,
  casecommmember: caseCommMemberReducer
});

export default rootReducers;
