import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "react-bootstrap/Container";
import RouteList from "./routes";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import ErrorBoundary from "./common/Error/ErrorBoundry";
import { Link, useNavigate } from "react-router-dom";
import Newtab from "./common/Error/NewTab";

function App() {
  return (
    <>
      <Router>
        <Container data-testid="container">
          <Provider store={store}>
            <Newtab />
            <ErrorBoundary>
              <RouteList />
            </ErrorBoundary>
          </Provider>
        </Container>
      </Router>
    </>
  );
}

export default App;
