import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ViewTerms from "../Content/ViewTerms";
// import WordContentViewer from "../Content/WordContentViewer";
const TermsofUse = ({ show, closeHandler }) => {
  const onHide = () => {
    closeHandler();
  };
  return ( 
    <Modal
      size="lg"
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
      data-testid="modal"
    >
      <Modal.Header>
        <Modal.Title
          id="example-modal-sizes-title-sm"
          className="h5 modal-title"
        >
          Terms of Use
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "500px" }} className="modal-scrolling">       
       {
          <div className="react-file-viewer"><ViewTerms/></div>
       }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          data-testid="btntermsok"
          className="btn btn-success btn-sm"
          onClick={onHide}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsofUse;
