import React from "react";

const PatientInformationContent = (props) => {
  return (
    <div>
      <h2 className="mpi-hdr-3">Get Started</h2>
      <p className="mb-2">
        In order to access case information you'll need to provide at least{" "}
        <strong>{props.value}</strong> of the following...
      </p>
      <ul>
        <li>Date of Birth</li>
        <li>Zip code</li>
        <li>Member ID</li>
        <li>Group number</li>
      </ul>
      If you don't have all of this information you can contact us{" "}
      <a
        href={`mailto:${process.env.REACT_APP_SUBRO_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
      >
        via email
      </a>{" "}
      or call {`${process.env.REACT_APP_SUBRO_FAX}`} for assistance.
    </div>
  );
};

export default PatientInformationContent;
