import React, { Component } from "react";
import { Link } from "react-router-dom";
import MultiPalnLogo from "../../assets/img/MPI-logo-130.gif";
import Header from "../../layout/Header";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  /* istanbul ignore next */
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Header />
          <main className="container mb-5">
            <div className="d-flex justify-content-center mt-5">
              <div className="flow-lg text-center">
                <h6 className="">
                  The application has encountered an unexpected error. Please
                  try again after a short time, or you can contact us{" "}
                  <a
                    href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
                  >
                    via email
                  </a>{" "}
                  or call {`${process.env.REACT_APP_SUPPORT_PHONE}`} for
                  assistance.
                </h6>
                {/* <h1 class="h6">The application has encountered an unexpected error. Please try again after a short time, or you contact us ${process.env.REACT_APP_EMAIL_ADDRESS} or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance. `}</h1> */}
                <p className="loginbold">
                  Go to{" "}
                  <a
                    href="/case-information"
                    //onClick={(e) => window.location.assign("/case-information")}
                    data-testid="gotolink"
                  >
                    Home
                  </a>{" "}
                </p>
              </div>
            </div>
          </main>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
