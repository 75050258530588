import React from "react";
import CookieConsent from "react-cookie-consent";
const CookiesBanner = () => {
  return (
    <CookieConsent
      onAccept={(byScroll) => {}}
      debug={true}
      acceptOnScrollPercentage={80}
      ariaAcceptLabel="I understand"
      style={{ position: "absolute", left: "500px", width: "30%" }}
    >
        Our website uses cookies. By continuing to use our site, you are
        agreeing to our use of cookies.{" "}
    </CookieConsent>
  );
};

export default CookiesBanner;
