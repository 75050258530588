import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SendResponse from "./SendResponse";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../common/Loader/Loading";
import Button from "react-bootstrap/Button";
import awpServices from "../../services/awpServices";
import ErrorValidation from "../../common/Alert/ErrorValidation";
import ConfirmAlertModal from "../../common/Modal/ConfirmModal";
import UncaughtError from "../../common/Alert/UncaughtError";
import { Spinner } from "react-bootstrap";
import CommunicationDetails from "./CommunicationDetails";
import MessageHistoryGrid from "./MessageHistoryGrid";
import parse  from 'html-react-parser';
import axios from "axios";
import Cookies from "js-cookie";
import { _getCaseCommuncationDetails, _getCaseCommuncationMemberDetails, _getCaseCommuncationMessageHistory } from "../../redux/actions/AWP/caseCommunicationActions";


const CaseCommunication = () => {
  const location = useLocation();
  const dispatch = useDispatch(); //calling redux actions
  const [communicationId, setcommunicationId] = useState(location.state.casecommunicationid); 
  const [displaySendResponse, setdisplaySendResponse] = useState(true); 
  const [haserror, setHasError] = React.useState("");
  const [attachementsFlag, setAttachementsFlag] = useState(false); 
  const useremailid = Cookies.get("useremailid"); 
 
  const communcationDetails = useSelector((state) => state.casecommdetails); 
  const mesHistListdata = useSelector((state) => state.casecommhist);  
  const caseMemberDetails = useSelector((state) => state.casecommmember); 
    /////redux selector
    const { isloading, statuscode, errmsg, caseCommId, cceId, clientId, from, to, cc, subject, message, signature, attachements} 
    = useSelector((state) => state.casecommdetails); 

    useEffect(() => {
      if (window.performance) {
        if (performance.navigation.type == 1) {
          if (statuscode === null)
          {
            bindcasecommunicationdetails();
          }
         // alert( "This page is reloaded" );
        } else {
          //alert( "This page is not reloaded");
        }
      }
    }, []);

  async function bindcasecommunicationdetails()
  {
      await dispatch(_getCaseCommuncationDetails(communicationId, 0,useremailid));
      await dispatch(_getCaseCommuncationMessageHistory(communicationId,useremailid));
      await dispatch(_getCaseCommuncationMemberDetails(communicationId));
  }

  return (
    <>
      {Number(statuscode) === 417 || Number(statuscode) === 500 ? (
        <ErrorValidation
          errormsg={"opps,something went wrong!!"}
          type="danger"
        />
      ) :
        (<div>
          <h1
            className="mpi-hdr-5 bg-mpi-navy text-white p-2"
            data-testid="Caseinfo">
            Case Communication
          </h1>
          <div className="row">
            {communcationDetails.isloading && communcationDetails.isloading && <Loader />}
            <div className="col-md-6">
              <CommunicationDetails messageDetails={{ communicationId: communicationId, statuscode: statuscode, message: message !== null && message !== undefined ? parse(message) : "", from: from, to: to, cc: cc, subject: subject, signature: signature !== null && signature !== undefined ? parse(signature) : "", attachementlist: attachements, memberDetails: caseMemberDetails }} />
            </div>
            <div className="col-md-6 col-lg-5">
              {from !== null ? from.toLowerCase() !== useremailid.toLowerCase() ?
                <SendResponse commDetails={{ isnonphi: true, communicationId: communicationId, cceId: cceId, clientId: clientId, frommailid: useremailid, tomailid: from, memberDetails: caseMemberDetails }} />
                : "" : ""
              }
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <MessageHistoryGrid messageHistList={mesHistListdata} />
              {mesHistListdata.isloading && mesHistListdata.isloading && <Loader />}
            </div>
          </div>
        </div>)}{" "}
    </>
  )
};

export default CaseCommunication;