import Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoginAlert } from "../../common/Alert/LoginAlert";
import Loader from "../../common/Loader/Loading";
import awpServices from "../../services/awpServices";
import Button from "react-bootstrap/Button";
const Accountverify = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [exError, setExError] = useState("");
  const [loading, setIsloading] = useState(false);
  const [extext, setExtext] = useState("");
  const [reactivationLink, setIsReactivationLink] = useState(false);
  // console.log("ActivationToken", searchParams.get("ActivationToken")); // 'name'
  useEffect(async () => {
    /* istanbul ignore next */
    setExError("");
    /* istanbul ignore next */
    await Verify_Account();
  }, []);
  async function Verify_Account() {
    /* istanbul ignore next */
    setIsloading(true);
    /* istanbul ignore next */
    setExtext("");
    /* istanbul ignore next */
    await awpServices
      ._Account_verification(searchParams.get("ActivationToken"))
      .then((res) => {
        /* istanbul ignore next */
        setIsloading(false);
        /* istanbul ignore next */
        if (res.status === 200 || res.status === 201) {
          /* istanbul ignore next */
          Cookies.set("statetoken", res.data.stateToken);
          /* istanbul ignore next */
          navigate(`/setpassword`, {
            state: { token: res.data.stateToken },
          });
          /* istanbul ignore next */
          setExtext("");
        } else {
          /* istanbul ignore next */
          setExError(res.data.statusText);
          /* istanbul ignore next */
          setExtext("");
        }
      })
      .catch((err) => {
        /* istanbul ignore next */
        setIsloading(false);
        /* istanbul ignore next */
        if (err.response.data.statusCode === 401) {
          /* istanbul ignore next */
          setExError("Token Expired.");
          /* istanbul ignore next */
          setExtext(
            `Your account activation token is no longer valid.This can happen if you clicked your activation link after creating your account,your activation link expired, or the URL is incorrect.`           
          );
          setIsReactivationLink(true);
        } else if (err.response.data.statusCode === 403) {
          /* istanbul ignore next */
          setExError("User already active.");
          /* istanbul ignore next */
          setExtext("");
        } else {
          /* istanbul ignore next */
          setExError(
            `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
          );
          /* istanbul ignore next */
          setExtext("");
        }
      });
  }

  return (
    <div>
      {" "}
      {loading && <Loader />}
      {exError !== "" ? (
        <Fragment>
          <LoginAlert type={"danger"} errmsg={exError} formattext={extext} />
          {reactivationLink === true ?
          <p className="">            
            We’ll help you to get reactivated.Please click <Link to="/reactivationlinkemail">here</Link>.
          </p> : <p className="loginbold">
            Click to{" "}
            <Link to="/" data-testid="gotolink">
              Sign in
            </Link>{" "}
          </p>}   
        </Fragment>
      ) : null}      
    </div>
  );
};

export default Accountverify;
