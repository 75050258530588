import React from "react";

const UncaughtError = () => {
  return (
    <p className="errmsg">
      The application has encountered an unexpected error. Please try again
      after a short time, or you can contact us{" "}
      <a
        href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
      >
        via email
      </a>{" "}
      or call {`${process.env.REACT_APP_SUPPORT_PHONE}`} for assistance.
    </p>
  );
};

export default UncaughtError;
