import React, { Fragment, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { _submitPartyInformation } from "../../redux/actions/AWP/partyInfoActions";
import { useDispatch } from "react-redux";

import awpServices from "../../services/awpServices";
import UncaughtError from "../../common/Alert/UncaughtError";
const CaseDetailsMismatch = (props) => {
  const { partydata, fieldname, type, attorneytype } = props;
  let dispatch = useDispatch();

  const [searchfield, setSearchField] = useState("");
  const [errSearchfield, seterrSearchfield] = useState("");
  const [searchfieldtext, setSearchFieldtext] = useState(fieldname);
  let [apicount, setApiCount] = useState(0);
  const [haserror, setHasError] = useState("");
  /* istanbul ignore next */
  const _onfindCliam_Handler = async () => {
    sessionStorage.removeItem("cliamno");
    setSearchFieldtext("");
    setHasError("");
    /* istanbul ignore else */
    if (searchfield === null || searchfield === "") {
      seterrSearchfield("Please enter your claim # !!");
    } else {
      setApiCount(++apicount);
      setSearchFieldtext(searchfield);
      sessionStorage.setItem("cliamno", searchfield);
      await dispatch(
        _submitPartyInformation(
          {
            claimno: searchfield,
            firmname: "",
            partytype: type,
            attorneytype: attorneytype,
          },
          partydata
        )
      );
    }
  };
  /* istanbul ignore if */
  if (apicount === 3) {
    sessionStorage.setItem("apicallcount", apicount);
  }
  const _onsearch_Handler = (e) => {
    /* istanbul ignore next */
    seterrSearchfield("");
    /* istanbul ignore next */
    setSearchField(e.target.value);
    /* istanbul ignore next */
    setHasError("");
  };

  return (
    <div data-testid="partymismatch">
      <Alert variant="warning">Your claim # not found.</Alert>

      {sessionStorage.getItem("apicallcount") === "3" ? (
        <h6 style={{ color: "red" }}>
          {`We’re having trouble matching your claim # to this case. Please use one of the contact methods in the Tip box below to let us know who you are.`}
        </h6>
      ) : (
        <p>
          {`You'll need to provide a matching your claim # to see additional information.`}
        </p>
      )}

      <div className="row">
        <div className="col-md-6">
          <p>
            You entered your claim # <strong>{fieldname}</strong>
          </p>
          <div className="row">
            <div className="form-group col-12 col-md-8">
              <label className="font-weight-bold" htmlFor="Claim">
                Your Claim #
              </label>

              <Form.Control
                id="Claim"
                type="text"
                className="form-control form-control-sm"
                data-testid="claimsearch"
                autoComplete="off"
                onChange={_onsearch_Handler}
                onPaste={(e) => {
                  /* istanbul ignore next */
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  /* istanbul ignore next */
                  e.preventDefault();
                  return false;
                }}
              />
              <span className="errmsg">{errSearchfield}</span>
            </div>
          </div>
          <Button
            variant="primary"
            className="btn btn-success btn-sm"
            type="submit"
            data-testid="btnnext"
            disabled={sessionStorage.getItem("apicallcount") === "3" && true}
            onClick={_onfindCliam_Handler}
          >
            Find
          </Button>{" "}
          {haserror && <UncaughtError />}
        </div>
        {sessionStorage.getItem("apicallcount") === "3" && (
          <div
            className="col-md-6"
            style={{ border: "1px groove black", maxWidth: "550px" }}
          >
            <small>
              Tip: You can find the your claim # previously provided, by
              checking the fax cover sheet on a document from MultiPlan.
            </small>{" "}
            <br />
            <small> Or you can contact the call center at 866-223-9974 </small>
            <br />
            <small>
              Or You can send us a message using the Send a Response message box
              above. It typically takes 3 – 5 days to process the information.
            </small>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseDetailsMismatch;
