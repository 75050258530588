import awpServices from "../../../services/awpServices";
import actioncreator from "./caseInformationActionCreater";

export const _getCaseInfomation =
  (_patientlastname, _caseid) => async (dispatch) => {
    try {
      dispatch(actioncreator.caseInfoLoadStart());
      const res = await awpServices
        ._getcaseInfo(_patientlastname, _caseid)
        .then((result) => {
          return result;
        });
      /* istanbul ignore next  */
      if (res.status === 200) {
        dispatch(actioncreator.PatientInfoLoadClear());
        dispatch(actioncreator.caseInfoLoadSuccess(res.data));
      }
    } catch (err) {
      dispatch(actioncreator.caseInfoLoadError(err.message));
    }
  };

/* istanbul ignore next */
export const _getSesionwith_MFA =
  (token, passcode, factorId) => async (dispatch) => {
    try {
      /* istanbul ignore next */
      dispatch(actioncreator.caseInfoLoadStart());
      /* istanbul ignore next */
      const res = await awpServices
        ._verify_MFA(token, passcode, factorId)
        .then((result) => {
          /* istanbul ignore next */          
          return result;
        });
      /* istanbul ignore next  */
      if (res.status === 200) {
        if(res.data.userType === "insurance" || res.data.userType === "attorney" )
        {
          dispatch(actioncreator._getsession_mfa(res.data));
        }
        else
        {
          window.location.assign("/page-unathorized");  
          //dispatch(actioncreator._getsession_mfa(res.data));        
        }
      }
    } catch (err) {
      /* istanbul ignore next */
      dispatch(actioncreator.caseInfoLoadError(err));
    }
  };
/* istanbul ignore next */
export const _getSesion_Logout = () => async (dispatch) => {
  /* istanbul ignore next */ dispatch(actioncreator.SessionOutClear());
};
