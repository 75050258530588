import React, { Fragment, useState, useRef } from "react";
import Alert from "react-bootstrap/Alert";

const CaseDetailsLORMsg = (props) => {
  return (
    <Fragment>
      <Alert variant="warning"> No Letter of Representation on File.</Alert>
      <div className="row">
        <div className="col-lg-8">
          <p>
            We current don't have a Letter of Representation or Medical
            Authorization on file.
          </p>

          <p>
            You can{" "}
            <a
              href={`mailto:${process.env.REACT_APP_SUBRO_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
            >
              email
            </a>
            , upload or fax a copy to {`${process.env.REACT_APP_SUBRO_FAX_LOR}`}
            .
          </p>
          <p>
            Check back later if you've already sent a copy. It typically takes
            3-5 business days to process.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default CaseDetailsLORMsg;
