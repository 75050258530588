import React from "react";

export const UploadSuccess = () => {
  return (
    <div
      data-testid="uploadsuccess"
      id="upload-confirmation"
      className="border border-success p-2 mt-1"
    >
      <h2 className="h6">
        <i className="fas fa-check-circle text-success"></i>{" "}
        <span className="text-success font-weight-bold">Upload complete</span>
      </h2>
      <p className="mb-0">
        Check back in 3-5 days for access to additional information.
      </p>
    </div>
  );
};

export const UploadError = () => {
  return (
    <div id="upload-error" data-testid="uploaderror" className="mt-1">
      <div
        className="alert alert-danger icon mt-2"
        style={{ width: "100%", height: "50%" }}
      >
        Upload error
      </div>
      {/* <div className="alert alert-danger icon mt-2"> Upload error</div> */}{" "}
      <p className="mb-0">There was a problem with your upload, try again</p>
    </div>
  );
};
