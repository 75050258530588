import actiontypes from "../../actiontypes/awpActiontypes";

const initialstate = {
  isloading: false,
  statuscode: null,
  errmsg: null,
  partydata: {},
  PHIData: null,
  NonPHIData: null,
  isPHI: null,
  isLOR: null,
  isAttorney: null,
  isInsurance: null,
};
const firmname = {
  label: "My firm name not listed",
  value: 0,
};

const partyInfoReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
    case actiontypes.PARTYINFO_LOAD_START:
      return {
        ...state,
        isloading: true,
      };
    case actiontypes.PARTYINFO_LOAD_SUCCESS:
      return {
        ...state,
        isloading: false,
        statuscode: payload.statusCode,
        partydata: payload.data.query,
        isPHI: payload.data.isPHI,
        isLOR: payload.data.isLOR,
        PHIData: payload.data.phiData,
        NonPHIData: payload.data.nonPHIData,
        isAttorney: payload.data.isAttorney,
        isInsurance: payload.data.isInsurance,
      };
    case actiontypes.PARTYINFO_LOAD_ERROR:
      return {
        ...state,
        isloading: false,
        errmsg: payload,
      };

    default:
      return state;
  }
};

export default partyInfoReducer;
