import apiClient from "./util/apiClient";

class awpServices {
  _getcaseInfo = (_patientlastname, _caseid) =>
    apiClient('CaseSearch').get("CaseSearch/SearchCaseIndex", {
      headers: {
        "Content-Type": "application/json",

        caseId: _caseid,
        patientlastName: encodeURI(_patientlastname)        
      },
    });
  _getinsurerList = () =>
    apiClient('CaseSearch').get("CaseSearch/GetInsurerList", {
      headers: { "Content-Type": "application/json" },
    });
  _getinsurerInfo = (_patientlastname, insurer) =>
    apiClient('CaseSearch').get("CaseSearch/SearchCaseIndex", {
      headers: {
        "Content-Type": "application/json",
        insurer: insurer,
        patientlastName: encodeURI(_patientlastname),
      },
    });
  _submitpatientInfo = (
    { dobDate, zip, memberId, groupId },
    { caseId, patientlastname, insurerName }
  ) =>
    apiClient('CaseSearch').get("CaseSearch/PatientInformation", {
      headers: {
        "Content-Type": "application/json",
        dobDate: dobDate,
        zip: zip,
        memberId: memberId,
        groupId: groupId,
        caseId: caseId,
        insurer: insurerName,
        patientLastName: encodeURI(patientlastname),
      },
    });

    _getCaseCommuncationMemberDetails = (_caseCommunicationId) =>
    apiClient('CaseSearch').get("CaseCommunication/v1/Message/"+_caseCommunicationId+"/memberdetails",{
      headers: {
        "Content-Type": "application/json",       
        caseCommunicationId: _caseCommunicationId        
      },
    });

    _getCaseCommuncationDetails = (_caseCommunicationId,_cceid,_userEmailId) =>
    apiClient('CaseSearch').get("CaseCommunication/v1/Message/"+_caseCommunicationId+"/details/"+_cceid,{
      headers: {
        "Content-Type": "application/json",
        LoginId : _userEmailId
      },
    });

    _getCaseCommuncationMessageHistory = (_caseCommunicationId,_userEmailId) =>
    apiClient('CaseCommunication').get("CaseCommunication/v1/Message/"+_caseCommunicationId+"/history",{
      headers: {
        "Content-Type": "application/json",       
        LoginId : _userEmailId
      },
    });

    _sendCommunicationReply = (resdata) =>
    apiClient('CaseCommunication').post("CaseCommunication/v1/Message/Response", resdata, {
      headers: {
        "content-type": "application/json",
        caseCommunicationId: resdata.caseCommId,
        insurer: resdata.insurer
      },
    });

    _saveCaseCommunicationUserActivity = (resdata) =>
    apiClient('CaseCommunication').post("CaseCommunication/v1/Message/UserActivity", resdata, {
      headers: {
        "content-type": "application/json"
      },
    });

  /* istanbul ignore next */
  _DownloadCommunicationAttachment = ({
    imageId,
    filename,
    fileext
  }) =>
    apiClient('CaseCommunication').get("CaseCommunication/v1/MessageFileDownload/" + imageId + "/download/" + filename + "/" + fileext, {
      headers: {
        "content-type": "application/json",

      },
      //responseType: "blob",
    }
    );
    
  _submitpartyinformation = (
    { partytype, firmname, claimno, attorneytype },
    { dobDate, zip, memberId, groupId, caseId, patientLastName, insurer }
  ) =>
    apiClient('PartyVerifyer').get("partyInformation/verifier", {
      headers: {
        "Content-Type": "application/json",
        party: partytype,
        attorneyName: firmname,
        claimNumber: claimno,
        dobDate: dobDate,
        zip: zip,
        memberId: memberId,
        groupId: groupId,
        caseId: caseId,
        insurer: insurer,
        patientLastName: encodeURI(patientLastName),
        attorneyType: attorneytype,
      },
    });
  _uploadfileLOR = (
    formdata,
    {
      party,
      attorneyName,
      dobDate,
      zip,
      memberId,
      groupId,
      caseId,
      patientLastName,
      insurer,
      attorneyType,
    }
  ) =>
    apiClient('PartyVerifyer').post("UploadFile/letter", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
        party: party,
        attorneyName: attorneyName,
        dobDate: dobDate,
        zip: zip,
        memberId: memberId,
        groupId: groupId,
        caseId: caseId,
        patientLastName: encodeURI(patientLastName),
        insurer: insurer,
        attorneyType: attorneyType,
      },
    });
  _Sendresponse = (resdata) =>
    apiClient('UpdateCase').post("UpdateCase/SendResponse", resdata, {
      headers: {
        "content-type": "application/json",
        caseId: resdata.caseId,
        insurer: resdata.insurer
      },
    });
  /* istanbul ignore next */
  _Recaptcha_Jwt_AuthToken = (authdata) =>
    apiClient('Authorization').post("Authorization/ValidateRecaptcha", authdata, {
      headers: {
        "content-type": "application/json",
      },
    });
  /* istanbul ignore next */
  _Invisible_Recaptcha = (authdata) =>
    apiClient('Authorization').post("Authorization/InvisibleRecaptcha", authdata, {
      headers: {
        "content-type": "application/json",
      },
    });
  /* istanbul ignore next */
  _Jwt_AuthToken = (authdata) =>
    apiClient('Authorization').post("Authorization/GenerateAccessToken", authdata, {
      headers: {
        "content-type": "application/json",
      },
    });

  /* istanbul ignore next */
  _Download_itemization = ({
    caseNumber,
    caseManagerName,
    caseManagerPhone,
    cliamno,
    partytype,
    firmname,
    attorneytype,
    caseId,
    insurer,
  }) =>
    apiClient('PartyVerifyer').post(
      "partyinformation/ExportItemization",
      {},
      {
        headers: {
          "content-type": "application/json",
          caseNumber: caseNumber,
          caseManagerName: caseManagerName,
          caseManagerPhone: caseManagerPhone,
          claimNumber: cliamno,
          attorneyType: attorneytype,
          attorneyName: firmname,
          party: partytype,
          caseId: caseId,
          insurer: insurer,
        },
        //responseType: "blob",
      }
    );
  /* istanbul ignore next */
  _Register_user = (reqdata) =>
    apiClient('Authorization').post(
      "authorization/api/v1/CreateUser",
      { profile: reqdata },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
  /* istanbul ignore next */
  _Account_verification = (token) =>
    apiClient('Authorization').post(
      "authorization/api/v1/ActivateUser",
      {},
      {
        headers: {
          "content-type": "application/json",
          token: token,
        },
      }
    );
  /* istanbul ignore next */
  _Reset_Password = (token, password) =>
    apiClient('Authorization').post(
      "authorization/api/v1/CreatePassword",
      {},
      {
        headers: {
          "content-type": "application/json",
          token: token,
          password: password,
        },
      }
    );
  /* istanbul ignore next */
  _login = (email, password) =>
    apiClient('Authorization').post(
      "authorization/api/v1/Login",
      {},
      {
        headers: {
          "content-type": "application/json",
          userName: email,
          password: password,
        },
      }
    );
  /* istanbul ignore next */
  _verify_MFA = (token, passcode, factorId) =>
    apiClient('Authorization').get(
      "authorization/api/v1/VerifyPasscode",

      {
        headers: {
          "content-type": "application/json",
          token,
          passcode,
          factorId,
        },
      }
    );
  /* istanbul ignore next */
  _resend_MFA = (token, factorId) =>
    apiClient('Authorization').get(
      "authorization/api/v1/ResendPasscode",

      {
        headers: {
          "content-type": "application/json",
          token,
          factorId,
        },
      }
    );
  /* istanbul ignore next */
  _signOut = (sessionId) =>
    apiClient('Authorization').delete("authorization/api/v1/logout", {
      headers: {
        "content-type": "application/json",
        sessionId: sessionId,
      },
    });
  /* istanbul ignore next */
  __sendForgetpasswordemail = (email) =>
    apiClient('Authorization').get("authorization/api/v1/ForgotPassword", {
      headers: { "content-type": "application/json", userName: email },
    });
  /* istanbul ignore next */
  _refersh_session = (sid) =>
    apiClient('Authorization').get("authorization/api/v1/RefreshSession", {
      headers: {
        "content-type": "application/json",
        sessionId: sid,
      },
    });

      /* istanbul ignore next */
  _getReactivationLink = (email) =>
  apiClient('Authorization').get("authorization/api/v1/GetReactivationLink", {
    headers: { "content-type": "application/json", userName: email },
  });
}

export default new awpServices();
