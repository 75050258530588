import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner'
const Loader = () => {

    return(
        <div className='container'>
            <div className='spinner-bg'>
                <Spinner animation="border" variant="primary"  className='plzWait'/>
          </div>
        </div>
    )
}

export default Loader