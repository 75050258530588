import React, { Fragment, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import CaseDetailsMismatch from "./CaseDetailsMismatch";
import CaseDetailsPHI from "./CaseDetailsPHI";
import CaseDetailsUploadLOR from "./CaseDetailsUploadLOR";
import SendResponse from "./SendResponse";

import { useNavigate, useLocation } from "react-router-dom";
import { _submitPartyInformation } from "../../redux/actions/AWP/partyInfoActions";
import Loader from "../../common/Loader/Loading";
import CaseFirmMismatch from "./CaseFirmMismatch";
import CaseDetailsLORMsg from "./CaseDetailsLORMsg";
import CaseDetailsNonPHI from "./CaseDetailsNonPHI";
import Button from "react-bootstrap/Button";
import awpServices from "../../services/awpServices";
import ErrorValidation from "../../common/Alert/ErrorValidation";
import ConfirmAlertModal from "../../common/Modal/ConfirmModal";
import UncaughtError from "../../common/Alert/UncaughtError";
import { Spinner } from "react-bootstrap";

const CaseDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [haserror, setHasError] = React.useState("");
  const [showdisclaimer, setShowDisclaimer] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {
    partydata,
    statuscode,
    errmsg,
    isloading,
    isPHI,
    isLOR,
    PHIData,
    NonPHIData,
    isAttorney,
    isInsurance,
  } = useSelector((state) => state.partyinfo);

  useEffect(() => {
    /* istanbul ignore next */
    if (statuscode === null) {
      /* istanbul ignore next */ bindcasedetails();
      setHasError("");
      /* istanbul ignore next */ setShowDisclaimer(false);
    }
  }, [statuscode === null]);

  async function bindcasedetails() {
    /* istanbul ignore next */ await dispatch(
      _submitPartyInformation(
        {
          claimno:
            sessionStorage.getItem("cliamno") === null ||
            sessionStorage.getItem("cliamno") === ""
              ? location.state.claimno
              : sessionStorage.getItem("cliamno"),
          firmname:
            sessionStorage.getItem("firmname") === null ||
            sessionStorage.getItem("firmname") === ""
              ? location.state.firmname
              : sessionStorage.getItem("firmname"),
          partytype: location.state.partytype,
          attorneytype: location.state.attorneytype,
        },
        {
          dobDate: location.state.dobDate,
          zip: location.state.zip,
          memberId: location.state.memberid,
          groupId: location.state.group,
          caseId: location.state.caseId,
          patientLastName: location.state.patientlastname,
          insurer: location.state.insurer,
        }
      )
    );
  }
  /* istanbul ignore next */
  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  /* istanbul ignore next */
  function saveByteArray(reportName, byte) {
    var blob = /* istanbul ignore next */ new Blob([byte], {
      type: "application/pdf",
    });
    var link = /* istanbul ignore next */ document.createElement("a");
    link.href = /* istanbul ignore next */ window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  const download_itemization = async (evt) => {
    /* istanbul ignore next */

    evt.preventDefault();
    const { caseNumber } = PHIData;
    const { caseManagerName, caseManagerPhone } = NonPHIData;
    const [cliamno, partytype, firmname, attorneytype, caseId, insurer] = [
      location.state.claimno,
      location.state.partytype,
      location.state.firmname,
      location.state.attorneytype,
      location.state.caseId,
      location.state.insurer,
    ];
    /* istanbul ignore next */
    setHasError("");
    setShowDisclaimer(false);
    /* istanbul ignore next */
    setLoading(true);
    await awpServices
      ._Download_itemization({
        caseNumber,
        caseManagerName,
        caseManagerPhone,
        cliamno:
          sessionStorage.getItem("cliamno") === null ||
          sessionStorage.getItem("cliamno") === ""
            ? cliamno
            : sessionStorage.getItem("cliamno"),
        partytype,
        firmname:
          sessionStorage.getItem("firmname") === null ||
          sessionStorage.getItem("firmname") === ""
            ? firmname
            : sessionStorage.getItem("firmname"),
        attorneytype,
        caseId,
        insurer,
      })
      .then((response) => {
        setLoading(false);
        if (response.status === 200 || response.status === 201) {
          var sampleArr = base64ToArrayBuffer(response.data.fileByte);
          /* istanbul ignore next */ saveByteArray(
            response.data.fileName,
            sampleArr
          );
          /* istanbul ignore next */ setHasError("");
        } else {
          setLoading(false);
          setHasError(
            `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
          );
        }
      })
      .catch((err) => /* istanbul ignore next */ setHasError(err));
  };

  const handleclose = () => {
    setShowDisclaimer(false);
  };
  return (
    <>
      {isloading && isloading && <Loader />}
      {Number(statuscode) === 417 || Number(statuscode) === 500 ? (
        <ErrorValidation
          errormsg={"opps,something went wrong!!"}
          type="danger"
        />
      ) : (
        <div>
          <h1
            className="mpi-hdr-5 bg-mpi-navy text-white p-2"
            data-testid="Caseinfo"
          >
            Case Information
          </h1>

          <div className="row">
            <div className="col-md-6">
              <CaseDetailsNonPHI
                NonPHIData={NonPHIData !== null && NonPHIData}
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <SendResponse isnonphi={NonPHIData} />
            </div>
          </div>
          <hr />

          {partydata.party === "attorney" && !isAttorney ? (
            <CaseFirmMismatch
              mismatch="attorney"
              partydata={partydata}
              fieldname={location.state.firmnametext}
              type={location.state.partytype}
              attorneytype={location.state.attorneytype}
              attorneytext={location.state.attorneytext}
            />
          ) : partydata.party === "attorney" && !isLOR ? (
            // <CaseDetailsUploadLOR partydata={partydata} />
            <CaseDetailsLORMsg />
          ) : partydata.party === "attorney" && isLOR && isPHI ? (
            <CaseDetailsPHI PHI={PHIData && PHIData} />
          ) : partydata.party === "insurance" && !isInsurance ? (
            <CaseDetailsMismatch
              mismatch="claim"
              partydata={partydata}
              fieldname={partydata.claimNumber}
              type={location.state.partytype}
              attorneytype={location.state.attorneytype}
            />
          ) : partydata.party === "insurance" && isPHI ? (
            <CaseDetailsPHI PHI={PHIData && PHIData} />
          ) : null}

          {/* conditions applied */}
          {/* <CaseDetails_UploadLOR />  */}
          {/* <CaseDetailsMismatch mismatch="claim" /> */}
          <div className="row">
            {" "}
            <div className="col-md-6">
              {partydata.party !== "insurance" && isAttorney && (
                <div className="row">
                  <div className="col-md-12">
                    <CaseDetailsUploadLOR partydata={partydata} />
                  </div>
                </div>
              )}{" "}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <p className="small"></p>
                  {isPHI && (
                    <>
                      <Button
                        variant="primary"
                        className="btn btn-success btn-sm"
                        type="submit"
                        data-testid="btnnext"
                        style={{
                          marginTop: `${
                            partydata.party !== "insurance" ? "20px" : "0px"
                          }`,
                        }}
                        title={"Download Itemization"}
                        // onClick={(evt) => download_itemization(evt)}
                        onClick={(e) => setShowDisclaimer(true)}
                      >
                        Download Itemization
                      </Button>{" "}
                      {showdisclaimer && (
                        <ConfirmAlertModal
                          show={showdisclaimer}
                          modalbody={`Benefits may be continuing and this is not necessarily
                        a final amount. Please contact our office to obtain a
                        final amount prior to issuing a payment.`}
                          btntext={"Ok"}
                          handleClose={handleclose}
                          btnHandle={(evt) => download_itemization(evt)}
                        />
                      )}
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="primary"
                          className="btnloading"
                        />
                      ) : null}
                      {haserror && <UncaughtError />}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default CaseDetails;
