import React, { Fragment } from "react";

const CaseDetailsNonPHI = ({ NonPHIData }) => {
  return (
    <Fragment>
      <dl className="row mt-3 small">
        <dt className="col-md-3" data-testid="CaseManager">
          Case Manager:
        </dt>
        <label className="col-md-9" data-testid="casemangerinfo">
          {NonPHIData &&
          (NonPHIData.caseManagerName?.trim() === "" ||
            NonPHIData.caseManagerName === null)
            ? ""
            : NonPHIData.caseManagerName}
        </label>

        <dt className="col-md-3" data-testid="CaseManagerEmail">
          Case Manager Email:
        </dt>
        <dd className="col-md-9">
          {NonPHIData &&
          (NonPHIData.caseManagerEmail?.trim() === "" ||
            NonPHIData.caseManagerEmail === null)
            ? ""
            : NonPHIData.caseManagerEmail}
        </dd>

        <dt className="col-md-3">Case Manager Phone:</dt>
        <dd className="col-md-9">
          {NonPHIData &&
          (NonPHIData.caseManagerPhone?.trim() === "" ||
            NonPHIData.caseManagerPhone === null)
            ? ""
            : NonPHIData.caseManagerPhone}
        </dd>

        {/* <dt className="col-md-3">Supervisor Email:</dt>
              <dd className="col-md-9">
                <a href="mailto:firstname.lastname@companyname.com">
                  {NonPHIData && NonPHIData.supervisorEmail}
                </a>
              </dd> */}

        <dt className="col-md-3">Case Status:</dt>
        <dd className="col-md-9">
          {" "}
          {NonPHIData &&
          (NonPHIData.caseStatus?.trim() === "" ||
            NonPHIData.caseStatus === null)
            ? ""
            : NonPHIData.caseStatus}
        </dd>
      </dl>
    </Fragment>
  );
};

export default CaseDetailsNonPHI;
