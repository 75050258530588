import React, { useEffect, useState, useRef } from "react";
import FileViewer from "react-file-viewer";

const ViewTerms = () => {
    const [filePath,setfilePath] = useState('./TermsOfUse.docx');
    const [fileType,setfileType] = useState('docx');

    return (     
        <FileViewer filePath={filePath} fileType = {fileType}> </FileViewer>
    );
  };
  
  export default ViewTerms;

