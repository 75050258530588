import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import awpServices from "../../services/awpServices";
import { LoginAlert } from "../../common/Alert/LoginAlert";

//import ResetAlert from "../../common/Alert/ResetPasswordAlert";

import Loader from "../../common/Loader/Loading";
import CryptoJS from "crypto-js";
import ResetPasswordContent from "../../common/Content/ResetPasswordContent";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const [isshown, setIsshown] = useState(false);
  const [alertflag, setAlertflag] = useState(false);
  const [loading, setIsloading] = useState(false);
  const [exError, setExError] = useState("");
  const [errmsg, setErrmsg] = useState("");

  const location = useLocation();
  let navigate = useNavigate(); //redirect to next page
  //generate  password
  const [meter, setMeter] = React.useState(false);
  const [showvalidaterule, setShowvalidaterule] = React.useState(false);
  //const [password, setPassword] = React.useState('');

  //console.log(location.state.token);
  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;
  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{12,}/g; // eight characters or more

  const passwordTracker = {
    uppercase: password.match(atLeastOneUppercase),
    lowercase: password.match(atLeastOneLowercase),
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const onsubmitHanlder = (e) => {
    /* istanbul ignore next */
    e.preventDefault();

    setAlertflag(false);
    if (passwordStrength < 5) {
      setShowvalidaterule(true);
    } else {
      /* istanbul ignore next */ setErrmsg("");
      var data = password;
      /* istanbul ignore next */
      var keyVal = CryptoJS.enc.Utf8.parse("32sFd3S6l3gm6IUn");
      /* istanbul ignore next */
      var iv = CryptoJS.enc.Utf8.parse("5wajvdyv5Xv1hq8J");
      /* istanbul ignore next */
      var encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(data),
        keyVal,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      /* istanbul ignore next */
      if (password === "" || password === null) {
        /* istanbul ignore next */ setErrmsg("Please Enter Password Field.");
      } else if (confirmpassword === "" || confirmpassword === null) {
        /* istanbul ignore next */ setErrmsg(
          "Please Enter Confirm Password Field."
        );
      } else if (password !== confirmpassword) {
        /* istanbul ignore next */ setErrmsg(
          "Confirm Password should  match with Password Field."
        );
      } else {
        /* istanbul ignore next */ setExError("");
        /* istanbul ignore next */ setIsloading(true);
        awpServices
          ._Reset_Password(location.state.token, encrypted)
          .then((res) => {
            /* istanbul ignore next */ setIsloading(false);
            /* istanbul ignore next */ if (
              res.status === 200 ||
              res.status === 201
            ) {
              /* istanbul ignore next */ //window.location.assign("/login"); //setAlertflag(true);
              sessionStorage.setItem("passwordsuccessset", "true");
              /* istanbul ignore next */ navigate("/login", {
                state: { isshow: true },
              });

              /* istanbul ignore next */ setExError("");
              /* istanbul ignore next */ setPassword("");
              /* istanbul ignore next */ setconfirmPassword("");
              /* istanbul ignore next */ setMeter(false);
              /* istanbul ignore next */ setShowvalidaterule(false);
            } else {
              /* istanbul ignore next */ setExError(res.data.statusText);
            }
          })
          .catch((err) => {
            /* istanbul ignore next */ setIsloading(false);
            /* istanbul ignore next */ if (
              err.response.data.statusCode === 401
            ) {
              /* istanbul ignore next */ setExError(
                `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
              );
            } else if (err.response.data.statusCode === 403) {
              /* istanbul ignore next */ setExError(
                err.response.data.statusText
              );
            } else {
              /* istanbul ignore next */ setExError(
                `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
              );
            }
          });
      }
    }
  };

  const ResetPasswordSet = (e) => {
    /* istanbul ignore next */ setIsshown(true);
    /* istanbul ignore next */ setExError("");
    // /* istanbul ignore next */ setAlertflag(false);
    /* istanbul ignore next */ setErrmsg("");
    /* istanbul ignore next */ setPassword(e.target.value);
  };
  const ResetconfirmPasswordSet = (e) => {
    /* istanbul ignore next */ setIsshown(true);
    /* istanbul ignore next */ setExError("");
    // /* istanbul ignore next */ setAlertflag(false);
    /* istanbul ignore next */ setErrmsg("");
    /* istanbul ignore next */ setconfirmPassword(e.target.value);
  };

  // console.log("passwordStrength", passwordStrength);
  // console.log("passwordStrengthtracer", passwordTracker);
  return (
    <>
      {/* <BrowerNotSupport num={2} /> */}
      <div className="row d-flex justify-content-between mt-2 createuser">
        <div className="col-md-6 col-lg-5 mb-4">
          <div className="bg-light p-4 border mr-0 mr-md-3 mr-lg-4">
            <h1 className="mpi-hdr-3 mb-3">Set Password</h1>
            {/* {alertflag &&  <ResetAlert />} */}

            {exError !== "" ? (
              /* istanbul ignore next */ <LoginAlert
                type={"danger"}
                errmsg={exError}
                formattext=""
              />
            ) : null}
            <Form
              className="flow-sm"
              onSubmit={onsubmitHanlder}
              autoComplete="off"
            >
              <Form.Group>
                <Form.Label className="font-weight-bold" id="password1">
                  Password
                </Form.Label>
                <Form.Control
                  onFocus={() => setMeter(true)}
                  value={password}
                  name="password"
                  id="password"
                  data-testid="password"
                  type={`${isshown ? "password" : "text"}`}
                  required
                  className="form-control form-control-sm"
                  // value={password}
                  aria-labelledby="password1"
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  //   onKeyPress={(e) => _patientLastNamespecialchar(e)}
                  onChange={ResetPasswordSet}
                />
                {meter && (
                  <div>
                    <div className="password-strength-meter"></div>
                  </div>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold" id="conpassword">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  id="conpassword"
                  type={`${isshown ? "password" : "text"}`}
                  data-testid="conpassword"
                  aria-labelledby="conpassword"
                  required
                  className="form-control form-control-sm"
                  value={confirmpassword}
                  onChange={ResetconfirmPasswordSet}
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
                <span className="errmsg">{errmsg}</span>
                {showvalidaterule && (
                  <div>
                    {passwordStrength < 5 && "Must contain "}
                    {!passwordTracker.uppercase && "uppercase, "}
                    {!passwordTracker.lowercase && "lowercase, "}
                    {!passwordTracker.specialChar && "special character, "}
                    {!passwordTracker.number && "number, "}
                    {!passwordTracker.eightCharsOrGreater &&
                      "12 characters or more"}
                  </div>
                )}
              </Form.Group>
              {loading ? (
                <Loader />
              ) : (
                <Button
                  variant="primary"
                  className="btn btn-success btn-sm g-recaptcha"
                  type="submit"
                  data-testid="btnreset"
                  disabled={alertflag && true}
                >
                  Set password
                </Button>
              )}
            </Form>
            <style jsx>
              {`
                .password-strength-meter {
                  height: 0.3rem;
                  background-color: lightgrey;
                  border-radius: 3px;
                  margin: 0.5rem 0;
                }

                .password-strength-meter::before {
                  content: "";
                  background-color: ${[
                    "red",
                    "orange",

                    "#03a2cc",
                    "#03a2cc",
                    "#0ce052",
                  ][passwordStrength - 1] || ""};
                  height: 100%;
                  width: ${(passwordStrength / 5) * 100}%;
                  display: block;
                  border-radius: 3px;
                  transition: width 0.2s;
                }
              `}
            </style>
          </div>
        </div>
        <div className="col-md-5 col-lg-6">
          <ResetPasswordContent />{" "}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
