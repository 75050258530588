import React from "react";

const PageNotFound = () => {
  return (
    <div className="container" data-testid="err404">
      <h1>Error 404 - Page Not Found</h1>
      <p>The Page your are looking for doesn't exist or has been moved.</p>
    </div>
  );
};

export default PageNotFound;
