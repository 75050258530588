import React, { Fragment } from "react";
import Alert from "react-bootstrap/Alert";
export const LoginAlert = ({ errmsg, formattext, type }) => {
  return (
    <div>
      {" "}
      <Fragment>
        {" "}
        <Alert variant={type}>
          {" "}
          {errmsg}
          <p className=" small mb-0" data-testid="alertmsgcontainer">
            {formattext}
            {/* Please check your email inbox to activate your account. */}
          </p>
        </Alert>
      </Fragment>
    </div>
  );
};
