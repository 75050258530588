import awpServices from "../../../services/awpServices";
import {
  partyInfoLoadError,
  partyInfoLoadSuccess,
  partyInfoLoadStart,
} from "../AWP/partyInfoActionCreaters";

export const _submitPartyInformation =
  (partyinfofields, patinetinfofields) => async (dispatch) => {
    try {
      /* istanbul ignore next */
      dispatch(partyInfoLoadStart());
      const res = await awpServices
        ._submitpartyinformation(partyinfofields, patinetinfofields)
        .then((result) => {
          return result;
        });
      /* istanbul ignore next */
      if (res.status === 200) {
        /* istanbul ignore next */
        dispatch(partyInfoLoadSuccess(res.data));
      }
    } catch (err) {
      /* istanbul ignore next */
      dispatch(partyInfoLoadError(err));
    }
  };
