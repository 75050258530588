import React from "react";
import { Link } from "react-router-dom";

const Unauthorized = () => {
  return (
    <div className="container">
      <h1>Error 401 - Unauthorized</h1>
      <p>
        You are not authorized. Please click here to <Link to="/">Sign In</Link>
      </p>
    </div>
  );
};

export default Unauthorized;
