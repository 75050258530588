import React, { Fragment, useState, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { UploadSuccess, UploadError } from "../../common/Alert/UploadMsg";

import awpServices from "../../services/awpServices";
import Spinner from "react-bootstrap/Spinner";
const CaseDetailsUploadLOR = (props) => {
  const { partydata } = props;
  const fileInput = useRef(null);
  const [respmsg, setRespMsg] = useState(null);
  const [validationerror, setValidationError] = useState("");
  const [btndisabled, setBtnDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  const refRecaptcha = useRef(null);
  const Upload_Handler = (e) => {
    
    e.preventDefault();
    const inpt = document.getElementsByTagName("input")[0];

    inpt.onclick = function () {
      this.value = null;
    };
    inpt.onChange = function () {};
    setValidationError("");
    setRespMsg(null);
    if (e.target.files) {
      const typeformat = ["pdf", "gif", "jpg", "png"];
      let { name, size, type } = e.target.files[0];
      //let docext1 = name.split(".").reverse()[0];
      //let docext = name.split(".")[1];
      let docext = type.split("/")[1];
      if (name.split(".").reverse()[0] === "jpg") docext = "jpg";
      if (name.split(".").reverse()[0] === "msg") docext = "msg";
      if (typeformat.includes(docext.toLowerCase())) {
        UploadFile(e.target.files[0], name, docext, size, e.target.value);
      } else {
        setValidationError(
          "Selected file type is not allowed. Please select correct file type."
        );
        e.target.value = "";
      }
    } else {
      /* istanbul ignore next */
      setValidationError("Please select  file !!");
    }
  };

  const UploadFile = (docfile, docname, docext, docsize, docpath) => {
    if (docsize > 16777216) {
      /* istanbul ignore next */
      setValidationError("File is too large. Max size is 16 mb !!");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(docfile);
      reader.onload = () => {
        let { result } = reader;
        let index = result.indexOf("base64") + 7;
        let filebyte = result.slice(index);
        submitFile(filebyte, docname, docext, docpath);
      };
    }
  };

  const submitFile = async (file, filename, ext, path) => {
    setBtnDisabled(true);
    const formData = new FormData();

    formData.append("filebyte", file);
    formData.append("Filename", filename);
    formData.append("fileExtension", `.${ext}`);
    formData.append("Path", path);
    /* istanbul ignore next */
    setLoading(true);
    await fnLORupload(formData, partydata);
    ///
  };
  async function fnLORupload(formData, partydata) {
    await awpServices
      ._uploadfileLOR(formData, partydata)
      .then((res) => {
        /* istanbul ignore next */
        setBtnDisabled(false);
        /* istanbul ignore next */
        setLoading(false);
        /* istanbul ignore if */
        if (res.status === 200 || res.status === 201) setRespMsg(true);
        else setRespMsg(false);
      })
      .catch((err) => {
        /* istanbul ignore next */
        setBtnDisabled(false);
        /* istanbul ignore next */
        setLoading(false);
        /* istanbul ignore next */
        setRespMsg(false);
      });
  }
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <p className="small">
            Suggested file types: <b>PDF</b>, <b>PNG</b>, <b>JPG</b>, <b>GIF</b>
            . Maximum file size: <b>16 MB</b>.
          </p>
          <React.Fragment>
            <input
              ref={fileInput}
              data-testid="fileupload"
              onChange={Upload_Handler}
              type="file"
              style={{ display: "none" }}
              variant="primary"
              className="btn-sm"
              // multiple={false}
            />
            <Button
              className="btn btn-success"
              disabled={btndisabled}
              data-testid="btnfileupload"
              onClick={() => fileInput.current.click()}
            >
              Upload Letter <i className="fas fa-file-upload ml-2"></i>
            </Button>{" "}
            <span className="errmsg"> {validationerror}</span>
          </React.Fragment>
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
              className="btnloading"
            />
          ) : (
            <>
              {" "}
              {respmsg === true ? (
                <UploadSuccess />
              ) : respmsg === false ? (
                <UploadError />
              ) : null}
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CaseDetailsUploadLOR;
