import React from "react";
import Form from "react-bootstrap/Form";
const Select = (props) => {
  return (
    <Form.Control
      as="select"
      role="select"
      required
      data-testid="selectinsurer"
      className="form-control form-control-sm"
      onChange={props.onChange_Handler}
      value={props.val}
    >
      <option className="d-none" value={"0"}>
        - Select -
      </option>
      {props.List &&
        props.List.map((option, index) => (
          <option key={index} value={option.value}>
            {" "}
            {option.label}
          </option>
        ))}
    </Form.Control>
  );
};

export default Select;
