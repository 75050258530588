import actiontypes from "../../actiontypes/awpActiontypes";

const initialState = {
  isloading: false,
  statuscode: null,
  errmsg: null,
  histlist: null
};

const caseCommMesHistReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actiontypes.CASECOMM_MES_HIST_LOAD_START:
      return {
        ...state,
        isloading: true,
        statuscode: null,
        errmsg: null,
      };
    case actiontypes.CASECOMM_MES_HIST_LOAD_SUCCESS:
      return {
        ...state,
        isloading: false,
        statuscode: payload.statusCode, //200
        histlist: payload.data        
      };
    case actiontypes.CASECOMM_MES_HIST_LOAD_ERRORS:
      return {
        ...state,
        isloading: false,
        errmsg: payload,
        statuscode: payload.statusCode, //404,500
      };
    default:
      return state;
  }
};

export default caseCommMesHistReducer;
