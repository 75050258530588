import actiontypes from "../../actiontypes/awpActiontypes";

export const partyInfoLoadStart = () => ({
  type: actiontypes.PARTYINFO_LOAD_START,
});

export const partyInfoLoadSuccess = (data) => ({
  type: actiontypes.PARTYINFO_LOAD_SUCCESS,
  payload: data,
});

export const partyInfoLoadError = (errmsg) => ({
  type: actiontypes.PARTYINFO_LOAD_ERROR,
  payload: errmsg,
});
