import awpServices from "../../../services/awpServices";
import patientinfocreater from "./patientInfoActionCreaters";

export const _patientInformation =
  (patinetinfofields, caseinfofields) =>
  /* istanbul ignore next */ async (dispatch) => {
    try {
      //console.log(patinetinfofields,"ss")
      /* istanbul ignore next */
      dispatch(patientinfocreater.patientInfoLoadStart());
      /* istanbul ignore next */
      const res = await awpServices
        ._submitpatientInfo(patinetinfofields, caseinfofields)
        .then((result) => {
          return result;
        });
      /* istanbul ignore next */
      if (res.status === 200) {
        /* istanbul ignore next */
        dispatch(patientinfocreater.patientInfoLoadSuccess(res.data));
      }
    } catch (err) {
      /* istanbul ignore next */
      dispatch(patientinfocreater.patientInfoLoadErrors(err.message));
    }
  };
