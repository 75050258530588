import React from 'react'

const NewTabPage = () => {
  return (
    <div className='container' data-testid="newtab">
            <main class="container mb-5">
                
            <div class="d-flex justify-content-center mt-5">
            <div class="flow-lg text-center">
                <h1 class="h4">You already have a browser tab or window open for this portal. </h1>
                <p class="h5">You must close it before you can view information for another case. </p>
            </div>
            </div>

            </main>
    </div>

  )
}

export default NewTabPage