import React, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";

import { Link } from "react-router-dom";
const LoginContent = (props) => {
  const [show, setShow] = useState(false); // for terms of use link
  return (
    <div>
      {props.link && (
        <Fragment>
          {" "}
          <p className="loginbold mb-1"> New to MultiPlan’s Subro Portal?</p>
          <p className="mb-2">
            <Link to="/signup">Submit a request </Link> and we’ll help you
            create an account.
          </p>{" "}
          <br />
          <p className="loginbold mb-1"> Forgot your password?</p>
          <p className="">
            {" "}
            We’ll help you <Link to="/forgetpasswordemail">reset it</Link> .
          </p>
        </Fragment>
      )}
      <br />
      <br />
      <br />
      <p className="loginbold mb-1">Having trouble signing in?</p>
      <p className="">
        Contact us{" "}
        <a
          href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
        >
          via email
        </a>{" "}
        or call {`${process.env.REACT_APP_SUPPORT_PHONE}`} for assistance.
      </p>
    </div>
  );
};

export default LoginContent;
