import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const ConfirmAlertModal = ({
  show,
  handleClose,
  modalbody,
  modaltitle,
  btntext,
  btnHandle,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{modaltitle}</Modal.Title>
          <a onClick={handleClose}>
            <i
              className="fa fa-window-close"
              aria-hidden="true"
              style={{ marginInlineStart: "auto", cursor: "pointer" }}
            ></i>
          </a>
        </Modal.Header>
        <Modal.Body>{modalbody}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="btn btn-success btn-sm btn btn-primary"
            onClick={btnHandle}
          >
            {btntext}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmAlertModal;
